import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {PlatformToggle} from "../../components/PlatformToggle";
import {Track} from "../../components/Track";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: column;
    height: 70dvh;
    
    label {
        margin-bottom: 10px;
    }
    
    > div.pad {
        margin-top: 16px;
    }
    
    .search {
        display: flex;
    }
    
    input {
        margin-right: 10px;
        width: 400px;
        ${props => props.isMobile ? "width: calc(100% - 86px);" : ""}
    }
    
    
`;

const STracks = styled.div`
    overflow: auto;
`;

const api = new API();

export function AddTrackModal({isOpen, setOpen, room, setNewTrack=null, isMobile=isMobileDevice()}) {
    const [platform, setPlatform] = useState();
    const [user, setUser] = useState();
    const [results, setResults] = useState([]);
    const searchInput = useRef(null);

    useEffect(() => {
        api.getUser().then((resp) => {
            setUser(resp);
        })
    }, []);

    const search = () => {
        const query = searchInput.current.value;
        if (query.length > 1) {
            api.searchTrack(platform, query).then((resp) => {
                setResults(resp);
            });
        }
    };

    const addTrack = (tid) => {
        const track = results.filter((tr) => tr.identifier === tid)[0];
        setResults([]);
        setOpen(false);
        if (!!setNewTrack) {
            setNewTrack(track);
            return;
        }

        api.addTrack(room.hash, platform, track).then((resp) => {

        })
    };

    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>Добавить трек</span></ModalHeader>
        <div>
            <SContent isMobile={isMobile}>
                {user && <PlatformToggle setPlatform={setPlatform} hasVk={user.has_vk_music} hasYm={user.has_ym_music} />}
                <div className="search pad">
                    <input ref={searchInput} type="text" id="create_room_name" placeholder="Введите название трека"/>
                    <Button size={isMobile ? "icon" : "s"} isPrimary={false} onClick={search}>
                        {isMobile ? (
                            <img src="/icons/search.svg" />
                        ) : "Найти"}
                    </Button>
                </div>
                <STracks className="pad">
                    {results.map((tr) => {
                        return <Track key={tr.identifier} artist={tr.artist} title={tr.title} tid={tr.identifier} cover={tr.cover} identifier={tr.identifier} onSelect={addTrack}/>
                    })}
                </STracks>
            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setOpen(false)}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
