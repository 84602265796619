import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: column;
    
    a {
        font-weight: 600;
        color: white;
    }
    
    label {
        margin-bottom: 10px;
    }
    
    > input {
        margin-bottom: 15px;
    }
    
    .qr {
        width: 100%;
        text-align: center;
        margin: 30px 0;
    }
    
    .qr img {
        width: 200px;
        height: 200px;
    }
`;

const api = new API();
const QR_API = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&color=255-255-255&bgcolor=161616&data=";

export function AuthVkModal({isOpen, setOpen, isMobile=isMobileDevice()}) {
    const [error, setError] = useState(null);
    const [redirectUri, setRedirectUri] = useState(null);
    const [step, setStep] = useState(1);
    const [login, setLogin] = useState(null);
    const [password, setPassword] = useState(null);
    const [smsSid, setSmsSid] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSmsLoading, setSmsLoading] = useState(false);

    const loginInput = useRef(null);
    const passwordInput = useRef(null);
    const smsInput = useRef(null);
    const linkInput = useRef(null);

    const parseQuery = (queryString) => {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    };

    const auth_link = () => {
        let lnk = linkInput.current.value;
        let link_parsed = parseQuery(lnk.split("#")[1]);
        let at = link_parsed['access_token'];
        let secret = link_parsed['secret'];
        setLoading(true);
        api.authVkLink(at, secret).then((resp) => {
            if (resp.success) {
                window.location.reload()
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const auth = () => {
        if (step === 3) return auth_link();

        setError(null);
        setStep(1);

        let sms_code = smsInput.current?.value;
        if (sms_code && sms_code.length<3) sms_code = null;
        else if (!sms_code) sms_code = null;

        setLoading(true);
        api.authVk(loginInput.current?.value || login, passwordInput.current?.value || password, sms_code).then((resp) => {
            if (!resp.success) {
                setError(resp.error_description);
                if (resp.error === "need_validation" && resp.redirect_uri) {
                    setRedirectUri(resp.redirect_uri);
                    setLogin(loginInput.current?.value);
                    setPassword(passwordInput.current?.value);
                    if (passwordInput.current) passwordInput.current.value = "";
                    if (loginInput.current) loginInput.current.value = "";
                    setStep(2);
                    setSmsSid(resp.validation_sid);
                }
                console.log(resp);
            }
            else window.location.reload();
        }).finally(() => {
            setLoading(false);
        });
    };

    const requestCode = () => {
        setSmsLoading(true);
        api.requestVkSms(smsSid).finally(() => {
            setSmsLoading(false);
        })
    };

    const STEPS = {
        1: <SContent>
            <label>Логин</label>
            <input id="login" ref={loginInput} type="text" />
            <label>Пароль</label>
            <input id="password" ref={passwordInput} type="password" />
            {error &&
            <span style={{color: '#ff3a3a', marginBottom: '10px'}}>{error}</span>
            }
            <span style={{color: 'gray'}}>Мы не храним логин и пароль, данные нужны{!isMobile && <br />} только для получения токена.</span>
        </SContent>,

        2: <SContent>
            <label>Вк запросил SMS код, он может не придти. Если так и случилось - нажмите на кнопку ниже</label>
            <Button size="s" onClick={requestCode} isRich={false} isPrimary={false} isLoading={isSmsLoading}>Запросить код</Button>
            <label style={{marginTop: '15px'}}>Код из SMS</label>
            <input id="sms" ref={smsInput} type="text" />
        </SContent>,

        3: <SContent>
            <label>Сканируйте QR код или передите по <a href={redirectUri || "error"}>этой ссылке</a> для прохождения проверки.</label>
            <label>Важно: после прохождения проверки вам откроется страничка, где вас попросят не копировать данные из адресной строки. Пожалуйста, скопируйте адресную строку в браузере и вставьте в соответствующее поле ниже. После чего нажмите "Авторизация" ещё раз.</label>
            <div className="qr">
                <img src={QR_API + encodeURIComponent(redirectUri || "error")} />
            </div>
            <label>Адресная строка браузера</label>
            <input ref={linkInput} type="text" />
        </SContent>
    };

    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>Авторизация в Вк Музыке</span></ModalHeader>
        <div>
            {step && STEPS[step]}
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} onClick={auth} isLoading={loading}><span>Авторизация</span></Button>
            {step === 2 && <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setStep(3)}>Не получается</Button>}
            {step === 3 && <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setStep(2)}>Назад</Button>}
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setOpen(false)}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
