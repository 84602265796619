export const baseTheme = {
    colors: {
        primary: '#f45fff',
        secondary: '#0076ff',
        dark: '#272727',
        success: '#00ff74',
        danger: '#ff0045',

        bg: '#161616',
        bg_active: '#272727',
        font: '#fff',
        font_block: '#ffffffcc',
        font_secondary: '#656565',

        border: '#ffffff1f',
        input_color: '#9f9f9f'
    },

    blocks: {
        radius: '12px',
        padding: '16px 20px',
        margin: '16px'
    },

    buttons: {
        l: {
            width: '330px',
            height: '60px',
            font: '19px',
        },
        s: {
            width: '130px',
            height: '40px',
            font: '16px',
        },
        m : {
            width: '220px',
            height: '40px',
            font: "16px"
        },
        xs: {
            width: '90px',
            height: '30px',
            font: "14px",
            radius: '5px'
        },
        icon: {
            width: '36px',
            height: '36px',
            font: '28px'
        },
        radius: '8px',
        weight: '600'
    }
};
