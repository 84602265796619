import React, {useEffect, useRef, useState} from "react";

export function BlockingBanner({isActive}) {
    const [act, setAct] = useState(isActive);
    const r = useRef(null);

    useEffect(() => {
        if (act && !isActive) {
            r.current.style.display = 'block';
            setTimeout(() => {
                r.current.style.opacity = '1';
            }, 10);

        } else if (!act && isActive) {

            r.current.style.opacity = '0';
            setTimeout(() => {
                r.current.style.display = 'none';
            }, 300)
        }

        setAct(isActive)

    }, [isActive]);

    return <div className="banner" ref={r} />
}
