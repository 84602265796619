import React from "react";

export function Dropdown({options, onChange, value}) {
    return <select onChange={(e) => {
        onChange(e.target.value)
    }} value={value}>
        {options.map(x => {
            return <option value={x.value}>{x.label}</option>
        })}
    </select>
}
