import axios from 'axios';
import Cookies from "js-cookie";

export class VkAPI {
    //requestUrl = 'https://api.vk.com/method/users.get?v=5.199&fields=photo_200_orig';
    requestUrl =  '/api/user/vk';

    getCookie(cookie_name) {
        return Cookies.get(cookie_name);
    }

    resetCredentials() {
        Cookies.remove("access_token", {path: "/"});
        Cookies.remove("refresh_token", {path: "/"});
        Cookies.remove("state", {path: "/"});
        Cookies.remove("user_id", {path: "/"});
    }

    getCredentials() {
        const at = this.getCookie("access_token");
        const rt = this.getCookie("refresh_token");
        const state = this.getCookie("state");
        return {access_token: at, refresh_token: rt, state};
    }

    getUser() {
        const {state} = this.getCredentials();

        return new Promise((resolve, reject) => {
            /*return resolve({
                "id": 173282696,
                "photo_200_orig": "https://sun1-18.userapi.com/s/v1/ig2/wOQ66RdOLRaBvFtNTiT9xB392tMghz8VLKiKq-Hm_Tag58I9WSLch9Kconm-VvCmmJmz5giBOM8qFbElC6N1GAxA.jpg?quality=95&crop=664,125,1029,1029&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720&ava=1&cs=240x240",
                "first_name": "Игорь",
                "last_name": "Теплинский",
                "can_access_closed": true,
                "is_closed": false
            });*/


            axios.get(this.requestUrl + `?state=${state}`, {
                withCredentials: true
            }).then((resp) => {
                console.log(resp);
                console.log("redirects", resp.request.responseURL);
                if (resp.data.length) {
                    resolve(resp.data[0])
                }
            });
        })

    }

    makeRequest() {
        return axios.get(this.requestUrl, {
            withCredentials: true
        });

    }
}
