import styled from 'styled-components';
import React from "react";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";


const SBlockSide = styled.div`
    display: flex;
    align-items: center;
    
    ${(props) => props.col ? "flex-direction: column; width: 100%;" : ""}
    
    > h3 {
        ${props => props.isMobile ? "text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden; width: calc(100vw - 110px);" : ""}
    }
    
    > .title {
        width: 100%;
        text-align: left;
        margin-bottom: 15px;
    }
    
`;

export function BlockSide({children, col=false}) {
    return (
        <SBlockSide col={col} isMobile={isMobileDevice()}>{children}</SBlockSide>
    )
}
