export const GraphMath = {
    get_grid_gap: (length, cnt) => length / (cnt - 1),
    gen_number_labels: (from_num, to_num, places) => {
        const res = [];

        let last_num = from_num;
        let cur_num = from_num;
        for (let i = 0; i < places; i++) {
            if (res.length > 0 && last_num === parseInt(cur_num)) {
                res.push(null);
            } else {
                last_num = parseInt(cur_num);
                res.push(parseInt(cur_num));
            }
            cur_num += (to_num - from_num) / (places-1);
        }
        if (res.length < places) res.push(to_num);
        else res[res.length-1] = to_num;
        return res;
    }
};
