import React, {useEffect, useState} from "react";
import 'chart.js/auto';
import {Chart} from "react-chartjs-2";
import 'chartjs-adapter-luxon';
import {SGraph} from "./style";
import {Chart as ChartJS, LinearScale, LineController, LineElement, PointElement, Title} from "chart.js";
import {Dropdown} from "../../../../components/Dropdown";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

export function ActivityGraph({data: sourceData, title=null}) {
    const graphColors = ['#0076ff', '#f45fff', '#7bff61'];

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: title !== null,
                text: title,
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'minute',
                    tooltipFormat: 'd.M T',
                    displayFormats: {
                        minute: 'T'
                    }
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                min: 0
            }
        }
    };

    const defaultData = {
        labels: [],
        datasets: [
            {
                label: "Апвоуты",
                data: [],
                borderColor: graphColors[0],
                backgroundColor: graphColors[0],
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 4,
                yAxisID: 'y',
            },
            {
                label: "Голоса за скип",
                data: [],
                borderColor: graphColors[1],
                backgroundColor: graphColors[1],
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 4,
                yAxisID: 'y',
            },
            {
                label: "Предложенных треков",
                data: [],
                borderColor: graphColors[2],
                backgroundColor: graphColors[2],
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 4,
                yAxisID: 'y',
            }
        ],
    };

    const [graphData, setGraphData] = useState(defaultData);
    const [dataIsSet, setDataIsSet] = useState(false);
    const [graphType, setGraphType] = useState("all");

    const setupGraph = () => {
        let graph_d1 = sourceData.upvotes;
        let graph_d2 = sourceData.skips;
        let graph_d3 = sourceData.adds;
        let graph_d4 = sourceData.common;
        let dates = sourceData.dates;
        const data = {...defaultData};
        data.labels = [];
        data.datasets[0].label = "Апвоуты";
        data.datasets[0].data = [];
        data.datasets[1].data = [];
        data.datasets[2].data = [];

        for (let i = 0; i < graph_d1.length; i++) {
            if (graphType === "all") {
                data.datasets[0].data.push(graph_d1[i] === null ? NaN : graph_d1[i]);
                data.datasets[1].data.push(graph_d2[i] === null ? NaN : graph_d2[i]);
                data.datasets[2].data.push(graph_d3[i] === null ? NaN : graph_d3[i]);
            } else if (graphType === "common") {
                data.datasets[0].label = "Сумма показателей";
                data.datasets[0].data.push(graph_d4[i] === null ? NaN : graph_d4[i]);
                data.datasets = data.datasets.slice(0, 1);
            } else {
                const labels = {
                    "upvotes": "Апвоуты",
                    "skips": "Голоса за скип",
                    "adds": "Предложенных треков"
                };
                const graphs = {
                    "upvotes": graph_d1,
                    "skips": graph_d2,
                    "adds": graph_d3
                };
                const graph = graphs[graphType];
                data.datasets[0].label = labels[graphType];
                data.datasets[0].data.push(graph[i] === null ? NaN : graph[i]);
                data.datasets = data.datasets.slice(0, 1);

            }

            data.labels.push(new Date(dates[i]));
        }
        setGraphData(data);
        setGraphData(prev => {
            setDataIsSet(true);
            return prev;
        })
    };

    useEffect(() => {
        if (sourceData) {
            setupGraph();
        }
    }, [sourceData, graphType]);

    return <div style={{width: "100%"}}>
        <div style={{display: "flex"}}>
            <p style={{margin: "5px 0", marginRight: "20px"}}>Настройки графика: </p>
            <Dropdown value={graphType} onChange={setGraphType} options={[
                {value: "all", label: "Все показатели"},
                {value: "skips", label: "Голоса за скип"},
                {value: "upvotes", label: "Апвоуты"},
                {value: "adds", label: "Добавление треков"},
                {value: "common", label: "Общий график"}
            ]} />
        </div>
        <SGraph>

        {dataIsSet && <div style={{width: '100%', height: '300px'}} >
            <Chart options={options} data={graphData} type={'line'}  />
        </div>}
    </SGraph></div>
}
