import React, {useEffect, useRef, useState} from "react";
import {Header} from "../../../components/Header";
import {Container} from "../../../components/Container";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {BackButton} from "../../../components/BackButton";
import {BlockSide} from "../../../components/Block/BlockSide";
import {SPlaylistStats} from "../../PlaylistPage/PlaylistSettingsPage/PlaylistStats/style";
import {DoubleGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/doubleGraph";
import {ActivityGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/activityGraph";
import {Checkbox} from "../../../components/Checkbox";

const api = new API();

function NumberInput({numRange, value, setValue}) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const new_opt = [];
        for (let i = numRange[0]; i <= numRange[1]; i++) {
            new_opt.push(<option value={i}>{i}</option>);
        }
        setOptions(new_opt);
    }, [numRange]);

    const onChange = (e) => {
        setValue(parseInt(e.target.value))
    };

    return <select onChange={onChange} value={value}>
        {options}
    </select>;
}

export function RoomSettingsPage({isMobile=isMobileDevice()}) {
    const [room, setRoom] = useState(null);
    const [inited, setInited] = useState(false);

    const [enableSkip, setEnableSkip] = useState(false);
    const [enableUpvote, setEnableUpvote] = useState(false);
    const [enableAdd, setEnableAdd] = useState(false);
    const [progressiveSkip, setProgressiveSkip] = useState(false);
    const [progressiveUpvote, setProgressiveUpvote] = useState(false);
    const [progressiveAdd, setProgressiveAdd] = useState(false);
    const [minimalSkip, setMinimalSkip] = useState(null);
    const [skipCd, setSkipCd] = useState(null);
    const [upvoteCd, setUpvoteCd] = useState(null);
    const [addCd, setAddCd] = useState(null);


    const navigate = useNavigate();
    const {room_hash} = useParams();

    useEffect(() => {
        if (inited) {
            api.saveRoomSettings(room_hash, {
                enable_skip: enableSkip,
                enable_upvote: enableUpvote,
                enable_add: enableAdd,
                skip_cd: skipCd,
                upvote_cd: upvoteCd,
                add_cd: addCd,
                progressive_skip_cd: progressiveSkip,
                progressive_upvote_cd: progressiveUpvote,
                progressive_add_cd: progressiveAdd,
                min_skip_votes: minimalSkip
            });
        }
    }, [enableSkip, enableUpvote, enableAdd, progressiveSkip, progressiveAdd, progressiveUpvote, minimalSkip, skipCd, upvoteCd, addCd]);

    useEffect(() => {
        if (room) {
            setInited(true);
        }
    }, [room]);

    const update = () => {
        api.getRoom(room_hash).then((resp) => {
            setRoom(resp);
            setSkipCd(resp.skip_cd);
            setUpvoteCd(resp.upvote_cd);
            setAddCd(resp.add_cd);
            setMinimalSkip(resp.min_skip_votes);
        });
    };


    useEffect(() => {
        update();
    }, []);


    return <div>
        <Header  />
        <Container paddingTop="0px">

            <BackButton backPath={`/room/${room_hash}/`} />
            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Настройки комнаты</h1>

            <Block smallPadding={isMobile}>
                <BlockSide col={true}>
                    <SPlaylistStats isMobile={isMobile}>

                        <p>
                            <Checkbox defaultChecked={room?.enable_skip} name="skip" onChange={setEnableSkip}>Разрешить пропуск треков</Checkbox>
                        </p>
                        <div><div /></div>

                        <p>
                            <Checkbox defaultChecked={room?.progressive_skip_cd} name="skip_progressive" onChange={setProgressiveSkip}>Прогрессивный кулдаун пропуска</Checkbox>
                        </p>
                        <div><div /></div>

                        <p>Кулдаун пропуска</p>
                        <div>
                            <div>
                                <NumberInput numRange={[1,20]} value={skipCd} setValue={setSkipCd} />
                            </div>
                        </div>

                        <p>Минимальное количество голосов для пропуска</p>
                        <div>
                            <div>
                                <NumberInput numRange={[1,40]} value={minimalSkip} setValue={setMinimalSkip} />
                            </div>
                        </div>


                        <p />
                        <div />


                        <p>
                            <Checkbox defaultChecked={room?.enable_upvote} name="upvote" onChange={setEnableUpvote}>Разрешить голосование за трек</Checkbox>
                        </p>
                        <div><div /></div>

                        <p>
                            <Checkbox defaultChecked={room?.progressive_upvote_cd} name="upvote_progressive" onChange={setProgressiveUpvote}>Прогрессивный кулдаун голосования</Checkbox>
                        </p>
                        <div><div /></div>

                        <p>Кулдаун голосования за трек</p>
                        <div>
                            <div>
                                <NumberInput numRange={[1,20]} value={upvoteCd} setValue={setUpvoteCd} />
                            </div>
                        </div>


                        <p />
                        <div />


                        <p>
                            <Checkbox defaultChecked={room?.enable_add} name="add" onChange={setEnableAdd}>Разрешить добавление треков</Checkbox>
                        </p>
                        <div><div /></div>

                        <p>
                            <Checkbox defaultChecked={room?.progressive_add_cd} name="add_progressive" onChange={setProgressiveAdd}>Прогрессивный кулдаун добавления треков</Checkbox>
                        </p>
                        <div><div /></div>

                        <p>Кулдаун добавления трека</p>
                        <div>
                            <div>
                                <NumberInput numRange={[1,20]} value={addCd} setValue={setAddCd} />
                            </div>
                        </div>


                    </SPlaylistStats>
                </BlockSide>
            </Block>

        </Container>
    </div>
}
