import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {cleanup_after_setup} from "./utils";
import {isTouchDevice} from "../../playerUtils";

const SLyricsMobile = `
    .lyrics-line {
        font-size: 24px;
        margin: 20px 0;
    }
    
    .line-next {
        /*font-size: 27px;*/
        
    }
    
    .line-current {
        /*font-size: 30px;*/
    }
`;

const SLyrics = styled.div`
    text-align: center;
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    
    transition: 0.5s opacity;
    
    opacity: ${props => props.show ? "1" : "0"};

     -webkit-mask-image: linear-gradient(to bottom, #000000 75%, transparent 85%);
     mask-image: linear-gradient(to bottom, #000000 75%, transparent 85%);
    
    #lyrics-content {
        position: absolute;
        display: flex;
        top: 24%;
        padding: 25px;
        color: white;
        font-weight: 600;
        border-radius: 10px;
        font-size: 30px;
        transition: top 0.2s;
        text-align: center;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        
    }
    
    .lyrics-line {
        margin: 30px 0;
        font-size: 56px;
        width: 80%;
        opacity: 0.1;
        transition: opacity 0.3s, font-size 0.3s, transform 0.3s;
    }
    
    .line-next {
        /* font-size: 59px; */
        transform: scale(1.0535714);
        opacity: 0.5;
    }
    
    .line-current {
        opacity: 1;
        /* font-size: 62px; */
        transform: scale(1.1071429);
    }
    
    .line-gone {
        opacity: 0;
    
    }
    
    ${props => props.isMobile ? SLyricsMobile : ""}
`;

export function Lyrics({trackInfo, setHasLyrics, isMobile=isTouchDevice()}) {
    const [lyrics, setLyrics] = useState([]);
    const [tid, setTid] = useState(null);

    const setup = (ls) => {
        cleanup_after_setup();
        const res = [];
        for (let i = 0; i < ls.length; i++) {
            const l = ls[i];
            if (i === 0) {
                res.push(
                    <div className="lyrics-line line-next" id={"line-"+i}>{l.words}</div>
                )
            } else {
                res.push(
                    <div className="lyrics-line" id={"line-"+i}>{l.words}</div>
                )
            }

        }
        setLyrics(res);

    };

    useEffect(() => {
        const ls = trackInfo.lyrics || [];

        const newTid = trackInfo.id;
        if (newTid !== tid) {
            console.log("lyrics: track changed");
            document.getElementById("lyrics-content").style.top = "24%";
            //document.getElementById("lyrics-content").innerHTML = '';
            console.log("lyrics: ls length", ls.length);
            if (ls.length) {
                setup(ls);
                console.log("lyrics setup1", ls);
            } else {
                setLyrics([]);
            }
            console.log("lyrics: set hl:", !!ls.length);
            setHasLyrics(!!ls.length);
            setTid(newTid);
        }

        if (newTid === tid && ls.length && !lyrics.length) {
            setup(ls);
            console.log('lyrics setup2', ls);
        }
    }, [trackInfo]);

    return <SLyrics isMobile={isMobile} show={!!lyrics.length}>
        <div id="lyrics-content">
            {lyrics}

        </div>
    </SLyrics>
}
