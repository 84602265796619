import axios from 'axios';
import Cookies from "js-cookie";
import {VkAPI} from "./vk";

export class API {
    baseDomain = 'https://klzmmusicmanager.ru/api';//'http://127.0.0.1:8000/api';//
    ws_url = `wss://klzmmusicmanager.ru:8081/ws/room/`;
    vk = new VkAPI();

    getUserId() {
        return Cookies.get("user_id");
    }

    makeRequest(endpoint, method='get', data={}) {
        const url = `${this.baseDomain}/${endpoint}`;

        if (method === "get") {
            return axios.get(url, {
                headers: {
                    "Auth": this.getUserId()
                }
            })
        } else if (method === "post") {
            return axios.post(url, data,{
                headers: {
                    "Auth": this.getUserId()
                }
            })
        }
    }

    wrap(data_key, ...args) {
        return new Promise((resolve, reject) => {
            this.makeRequest(...args).then((resp) => {
                resolve(data_key ? resp.data[data_key] : resp.data);
            }).catch((e) => {
                reject(e);
            })
        })
    }

    getUser() {
        return this.wrap(null, `user/`);
    }

    getRooms() {
        return new Promise((resolve, reject) => {
            this.makeRequest("room/").then((resp) => {
                resolve(resp.data.rooms);
            })
        })
    }

    createRoom(name) {
        return new Promise((resolve, reject) => {
            this.makeRequest("room/?room_name="+name, "post").then((resp) => {
                resolve();
            })
        })
    }

    getRoom(hash) {
        return this.wrap(null, `room/${hash}`)
    }

    getSession(hash, session_id) {
        return this.wrap(null, `room/${hash}/session/${session_id}`);
    }

    getRoomStatus(hash) {
        return this.wrap(null, `room/${hash}/status`)
    }

    getRoomStats(hash) {
        return this.wrap(null, `room/${hash}/stats`);
    }

    getQueue(hash, kw='tracks', get_stats=false) {
        return this.wrap(kw, `room/${hash}/queue?get_stats=${get_stats}`)
    }

    upvote(hash, pid, tid) {
        return this.wrap(null, `room/${hash}/playlist/${pid}/track/${tid}/vote`, "post")
    }

    skip(hash) {
        return this.wrap(null, `room/${hash}/skip`, "post")
    }

    searchTrack(platform, query) {
        return this.wrap("tracks", `tracks/${platform}/search?query=${query}`);
    }

    addTrack(hash, platform, track) {
        return this.wrap(null, `room/${hash}/add_track`, "post", {
            platform: platform,
            identifier: track.identifier,
            artist: track.artist,
            title: track.title,
            duration: track.duration,
            cover: track.cover
        });
    }

    savePlaylistParams(hash, pid, params) {
        return this.wrap(null, `room/${hash}/playlist/${pid}/save_params`, "post", params);
    }

    nextPlaylist(hash) {
        return this.wrap(null, `room/${hash}/next_playlist`, "post")
    }

    getPlaylists(hash) {
        return this.wrap("playlists", `room/${hash}/playlists`)
    }

    createPlaylist(hash, title) {
        return this.wrap(null, `room/${hash}/playlist?playlist_name=${title}`, "post")
    }

    startPlaying(hash) {
        return this.wrap(null, `room/${hash}/start`, "post")
    }

    getPlaylist(hash, pid, get_stats=false) {
        return this.wrap(null, `room/${hash}/playlist/${pid}?get_stats=${get_stats}`)
    }

    startAnalysis(hash, pid) {
        return this.wrap(null, `room/${hash}/playlist/${pid}/analyze`, "post")
    }

    importPlaylist(platform, link) {
        return this.wrap("tracks", `tracks/${platform}/playlist/import?link=${encodeURIComponent(link)}`);
    }

    savePlaylist(hash, pid, tracks) {
        return this.wrap(null, `room/${hash}/playlist/${pid}/save`, "post", {"tracks": tracks.map((track) => {return {
            platform: track.platform,
            identifier: track.identifier,
            artist: track.artist,
            title: track.title,
            duration: track.duration,
            cover: track.cover
        }})});
    }

    authVk(login, password, code=null) {
        return this.wrap(null, `auth/vk`, "post", {
            login, password, code
        });
    }

    requestVkSms(sid) {
        return this.wrap(null, `auth/vk/request_sms?sid=${sid}`)
    }

    authVkLink(access_token, secret) {
        return this.wrap(null, `auth/vk/link`, "post", {
            access_token, secret
        });
    }

    getYmQr() {
        return this.wrap(null, `auth/ym/qr`);
    }

    authYm(csrf_token, track_id) {
        return this.wrap(null, `auth/ym?csrf_token=${csrf_token}&track_id=${track_id}`);
    }

    saveGraph(hash, pid, img_url, l, start_value, stop_value, type) {
        return this.wrap(null, `room/${hash}/playlist/${pid}/save_graph`, "post", {
            img_url, length: l, start_value, stop_value, graph_type: type
        });
    }

    boostRoom(hash, boost_type, value) {
        return this.wrap(null, `room/${hash}/boost`, "post", {
            boost_type, tracks_count: value
        });
    }

    activateNoCd(hash, mins) {
        return this.wrap(null, `room/${hash}/no_cd?mode_duration=${mins}`, "post", {});
    }

    getAudioLink(platform, identifier) {
        return this.wrap(null, `tracks/${platform}/get?track_identifier=${identifier}`);
    }

    nextTrack(hash, force=false) {
        return this.wrap(null, `room/${hash}/next?force=${force}`);
    }

    preloadTrack(hash) {
        return this.wrap(null, `room/${hash}/preload`);
    }

    getTimestampDelta() {
        return new Promise((resolve, reject) => {
            const start_ts = (new Date()).getTime();
            this.wrap(null, `user/ping`).then(resp => {
                const server_ts = resp.ts;
                const end_ts = (new Date()).getTime();
                const request_time = (end_ts - start_ts) / 2;
                let start_server_ts = server_ts - request_time;
                const delta = server_ts - end_ts + request_time;
                console.log(end_ts - server_ts, server_ts - start_ts,request_time, delta);
                resolve(0);
                //resolve(delta);
            }).catch(reject);
        });

    }

    saveRoomSettings(hash, settings) {
        return this.wrap(null, `room/${hash}/save`, "post", settings);
    }
}
