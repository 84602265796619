import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
   
    
    > div {
        
        font-size: 18px;
        font-weight: 400;
    }
`;

export function NeedAuthModal({isOpen, setOpen, isMobile=isMobileDevice()}) {
    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>Необходима авторизация</span></ModalHeader>
        <div>
            <SContent>
                <div>Нажмите на кнопку ниже, чтобы выполнить вход через VK</div>
            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={true} isPrimary={false} onClick={() => {
                window.location.href = "/api/auth/vkid/redirect";
            }}><span>Войти через VK</span></Button>
        </ModalFooter>

    </BaseModal>
}
