import styled from 'styled-components';
import {isMobileDevice} from "../../hooks/mobile/isMobile";

export const SRoomStatus = styled.div`
    width: 100px;
    padding: 4px 0;
    border-radius: 20px;
    margin: 0;
    background-color: ${({ theme, status }) => status === "awaiting" ? theme.colors.secondary : theme.colors.primary};
    
    p {
        margin: 0;
        text-align: center;
    }
`;

const statusToText = {
    "awaiting": "Ожидание",
    'playing': "Играет"
};

const SRoomMobileStatus = styled.div`
    
    > img {
        height: 18px;
    }
`;

export function RoomStatus({status, isMobile = isMobileDevice()}) {
    return isMobile ? (
            <SRoomMobileStatus className="flexVertCenter">
                <img src={status === "awaiting" ? "/icons/waiting.svg" : "/icons/play.png"} />
            </SRoomMobileStatus>
        ) : (<SRoomStatus status={status}><p>{statusToText[status]}</p></SRoomStatus>)}
