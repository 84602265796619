import React, {useEffect, useState} from "react";
import {Header} from "../../../components/Header";
import {Container} from "../../../components/Container";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {FloatingContainer} from "../../../components/FloatingContainer";
import {Button} from "../../../components/Button";
import {Bar} from "../../../components/Bar";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {Chart} from "react-chartjs-2";
import {DoubleGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/doubleGraph";
import {BackButton} from "../../../components/BackButton";
import {SPlaylistStats} from "../../PlaylistPage/PlaylistSettingsPage/PlaylistStats/style";
import {InputValueModal} from "../../../modals/InputValueModal";
import {ActivityGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/activityGraph";

const api = new API();

export function ControlsPage({isMobile=isMobileDevice()}) {
    const navigate = useNavigate();
    const [room, setRoom] = useState({});
    const [playlist, setPlaylist] = useState({});
    const [graphData, setGraphData] = useState(null);
    const [dataS1, setDataS1] = useState(null);
    const [dataS2, setDataS2] = useState(null);
    const [queue, setQueue] = useState({});
    const [stats, setStats] = useState({});

    const [valueModalOpen, setValueModalOpen] = useState(false);
    const [valueRange, setValueRange] = useState([1,5]);
    const [valueTitle, setValueTitle] = useState(null);
    const [valueDescription, setValueDescription] = useState(null);
    const [passValue, setPassValue] = useState({});

    /* loadings */
    const [isPlLoading, setIsPlLoading] = useState(false);
    const [isBoostLoading, setBoostLoading] = useState(false);
    const [isCalmLoading, setCalmLoading] = useState(false);
    const [isCdLoading, setCdLoading] = useState(false);


    const valueCallback = (v, pv) => {
        if (pv.type === "boost" ||  pv.type === "calm") {
            (pv.type === "boost" ? setBoostLoading : setCalmLoading)(true);
            api.boostRoom(room.hash, pv.type, v).finally(() => {
                (pv.type === "boost" ? setBoostLoading : setCalmLoading)(false);
            });
        } else {
            setCdLoading(true);
            api.activateNoCd(room.hash, v).finally(() => {
               setCdLoading(false);
            });
        }
    };

    const {room_hash} = useParams();

    const update = () => {
        api.getRoom(room_hash).then((resp) => {
            setRoom(resp);
            api.getPlaylist(room_hash, resp.active_playlist).then(resp_pl => {
                setPlaylist(resp_pl);
            });
        });
        api.getQueue(room_hash, null, true).then((resp) => {
            setGraphData(resp.graph);
            setQueue(resp.tracks);
            setStats(resp.stats);
        })
    };

    useEffect(() => {
        if (graphData && graphData.length) {
            const bpm = graphData.map(x => x[0]);
            const energy = graphData.map(x => x[1]);
            setDataS1(JSON.stringify(bpm));
            setDataS2(JSON.stringify(energy));
        }
    }, [graphData]);

    useEffect(() => {
        update()
    }, []);

    const nextPlaylist = () => {
        setIsPlLoading(true);
        api.nextPlaylist(room.hash).then((resp) => {
            if (resp.error === 'Finished') {
                navigate(`/room/${room_hash}/`);
            } else {
                update();
            }
        }).finally(() => {setIsPlLoading(false)});
    };

    const goBack = () => {
        navigate(`/room/${room.hash}/`)
    };

    const boost = () => {
        setValueRange([2,5]);
        setValueDescription("трека(-ов)");
        setValueTitle("Буст энергичности");
        setPassValue({type: "boost"});
        setValueModalOpen(true);
    };

    const calm = () => {
        setValueRange([2,5]);
        setValueDescription("трека(-ов)");
        setValueTitle("Понижение энергичности");
        setPassValue({type: "calm"});
        setValueModalOpen(true);
    };

    const no_cd = () => {
        setValueRange([2,10]);
        setValueDescription("минут(-ы)");
        setValueTitle("Отключить кулдауны (кроме пропуска)");
        setPassValue({type: "cd"});
        setValueModalOpen(true);
    };

    return <div>
        <Header  />
        <Container paddingTop="0px">

            {!isMobile && <BackButton backPath={`/room/${room_hash}/`} />}
            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Управление комнатой {room.name || ""}</h1>

            <Block smallPadding={isMobile}>
                <div style={{width: '100%'}}>
                    <SPlaylistStats isMobile={isMobile}>

                        <p>Сейчас играет плейлист</p>
                        <div>
                            <div>{playlist.name}</div>
                        </div>

                        <p>Треков в очереди / в плейлисте</p>
                        <div>
                            <div>{queue.length} / {playlist.tracks?.length}</div>
                        </div>

                        <p>Треков предложено</p>
                        <div>
                            <div>{stats.tracks_added_count}</div>
                        </div>

                        <p>Голосов за скип</p>
                        <div>
                            <div>{stats.skip_votes_count}</div>
                        </div>

                    </SPlaylistStats>

                    <div style={{'display': 'flex', gap: '15px', marginTop: '20px'}}>
                        <Button size="m" responsive={true} isPrimary={false} onClick={boost} isLoading={isBoostLoading}>Буст энергичности</Button>
                        <Button size="m" responsive={true} isPrimary={false} onClick={calm} isLoading={isCalmLoading}>Понижение энергичности</Button>
                        <Button size="m" responsive={true} isPrimary={false} onClick={no_cd} isLoading={isCdLoading}>Режим Без кулдауна</Button>
                    </div>

                </div>
            </Block>

            <Block smallPadding={isMobile}>
                <DoubleGraph dataS1={dataS1} dataS2={dataS2} showLabel={false} title="Показатели энергичности и BPM текущей очереди" />
            </Block>

            <Block smallPadding={isMobile}>
                <ActivityGraph data={stats.activity_graphs} title="Показатели активности пользователей в текущей сессии" />
            </Block>

            {!isMobile && <FloatingContainer>
                {room.is_host && <Button size="m" isPrimary={false} onClick={nextPlaylist} isLoading={isPlLoading}>Следующий плейлист</Button>}
            </FloatingContainer>}

            {isMobile && (
                <Bar buttons={[
                    {text: "Назад", icon: "/icons/arrow_turn.svg", onClick: goBack},
                    room.is_host ? {text: "Некст плейлист", icon: "/icons/skip_playlist.svg", onClick: nextPlaylist, isLoading: isPlLoading} : null
                ]} />
            )}

            <InputValueModal isOpen={valueModalOpen} setOpen={setValueModalOpen} numRange={valueRange}
                             title={valueTitle} valueDescription={valueDescription} callback={valueCallback} passValue={passValue} />
        </Container>
    </div>
}
