import axios from "axios";

const NOTES = [
    "C",
    ["C#", "D♭"],
    "D",
    ["D#", "E♭"],
    "E",
    "F",
    ["F#", "G♭"],
    "G",
    ["G#", "A♭"],
    "A",
    ["A#", "B♭"],
    "B",
];

const CONCERT_PITCH = 440;
const A4_MIDI = 69;
const A = Math.pow(2, 1 / 12);
const C0_PITCH = 16.35;

//const sequence = ["E5", "G5", "G4", "G5"];
const sequence = [[950, 975], [1205, 1230], [895, 910], [1575, 1590]];
const normal_delays = [1112, 2745, 4545, 6528];

const url = "/sounds/tones6.mp3";

const fetchAudio = (audio_url=url) => {
    return new Promise((resolve, reject) => {
        axios.get(audio_url, { responseType: 'arraybuffer' }).then(r => resolve(r.data))
    })
};

export function process() {
    return new Promise((resolve, reject) => {
        const results = [];

        navigator.mediaDevices.getUserMedia({
            audio: {
                mandatory: {
                    googEchoCancellation: "false",
                    googAutoGainControl: "false",
                    googNoiseSuppression: "false",
                    googHighpassFilter: "false",
                },
                optional: [],
            },
        }).then(audioStream => {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            const source = audioContext.createMediaStreamSource(audioStream);
            source.connect(analyser);
            const bufferSource = audioContext.createBufferSource();



            fetchAudio().then(buf => {
                audioContext.decodeAudioData(buf).then(audioData => {
                    bufferSource.buffer = audioData;
                    bufferSource.connect(audioContext.destination);
                    bufferSource.start();
                    const startTime = audioContext.currentTime * 1000;

                    const int = setInterval(() => {
                        const buffer = new Float32Array(2048);
                        analyser.getFloatTimeDomainData(buffer);
                        const frequency = autoCorrelate(buffer, audioContext.sampleRate);
                        // Volume calculations
                        let sumSquares = 0.0;
                        for (const amplitude of buffer) {
                            sumSquares += Math.pow(amplitude, 2);
                        }

                        const res_step = results.length;

                        if (frequency && res_step < sequence.length) {
                            let s = sequence[res_step];
                            if (frequency >= s[0] && frequency <= s[1]) {
                                let ts = audioContext.currentTime * 1000;
                                let ts_from_start = ts - startTime;
                                results.push({
                                    freq: frequency,
                                    ts: ts,
                                    ts_from_start: ts_from_start,
                                    delay: ts_from_start - normal_delays[res_step]
                                })
                            }
                        }
                    }, 0);
                    setTimeout(() => {
                        clearInterval(int);
                        audioStream.getAudioTracks().forEach(function (track) {
                            track.stop();
                        });
                        if (results.length === sequence.length) {
                            resolve(results);
                        } else {
                            reject();
                        }

                    }, 7900);
                });

            });


        }).catch(() => {
            reject();
        });
    });

}

export const valuesAtFrequency = (freq, vol = undefined) => {
    const N = Math.round(12 * Math.log2(freq / CONCERT_PITCH));
    const Fn = CONCERT_PITCH * Math.pow(A, N);
    const noteIndex = (N + A4_MIDI) % 12;
    const octave = Math.floor(Math.log2(Fn / C0_PITCH));

    return {
        frequency: freq,
        note: NOTES[noteIndex]?.[0],
        enharmonicNote: NOTES[noteIndex]?.[1] || NOTES[noteIndex]?.[0],
        noteFrequency: Fn,
        deviation: freq - Fn,
        octave,
        volume: vol,
    };
};

export const autoCorrelate = (buf, sampleRate) => {
    const RMS = Math.sqrt(
        buf.reduce((acc, el) => acc + Math.pow(el, 2), 0) / buf.length
    );
    if (RMS < 0.001) return NaN;

    const THRES = 0.2;
    let r1 = 0;
    let r2 = buf.length - 1;
    for (let i = 0; i < buf.length / 2; ++i) {
        if (Math.abs(buf[i]) < THRES) {
            r1 = i;
            break;
        }
    }
    for (let i = 1; i < buf.length / 2; ++i) {
        if (Math.abs(buf[buf.length - i]) < THRES) {
            r2 = buf.length - i;
            break;
        }
    }

    const buf2 = buf.slice(r1, r2);
    const c = new Array(buf2.length).fill(0);
    for (let i = 0; i < buf2.length; ++i) {
        for (let j = 0; j < buf2.length - i; ++j) {
            c[i] = c[i] + buf2[j] * buf2[j + i];
        }
    }

    let d = 0;
    for (; c[d] > c[d + 1]; ++d);

    let maxval = -1;
    let maxpos = -1;
    for (let i = d; i < buf2.length; ++i) {
        if (c[i] > maxval) {
            maxval = c[i];
            maxpos = i;
        }
    }
    let T0 = maxpos;

    let x1 = c[T0 - 1];
    let x2 = c[T0];
    let x3 = c[T0 + 1];
    let a = (x1 + x3 - 2 * x2) / 2;
    let b = (x3 - x1) / 2;

    return sampleRate / (a ? T0 - b / (2 * a) : T0);
};
