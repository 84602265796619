import styled from 'styled-components';
import React, {useState} from "react";


const SBlock = styled.div`
    background-color: ${({ theme }) => theme.colors.bg};
    margin-bottom: ${({ theme }) => theme.blocks.margin};
    padding: ${({ theme }) => theme.blocks.padding};
    border-radius: ${({ theme }) => theme.blocks.radius};
    border: 1px solid ${({ theme }) => theme.colors.border};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    ${(props) => props.width ? `width: ${props.width}px;` : ""}
    
    > div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        flex-grow: 1;
        
        ${(props) => props.height ? `overflow-y: auto; height: calc(${props.height});padding-right: 8px;` : ""}
    }
    
    
    
    ${(props) => props.is_clickable ? "cursor: pointer;" : ""}
    ${props => props.smallPadding ? "padding: 10px 8px;" : ""}
    
`;

export function Block({children, onClick, value, height, width, smallPadding=false, style={}}) {
    const [isClickable, setIsClickable] = useState(!!onClick);

    return (
        <SBlock style={style} width={width} height={height} is_clickable={isClickable} smallPadding={smallPadding} onClick={() => onClick ? onClick(value) : null}><div>{children}</div></SBlock>
    )
}
