import { useCallback, useEffect, useRef } from "react";


export function getMedian(arr) {
    arr.sort();
    let h = arr.length / 2;
    if (arr.length % 2 !== 0) return arr[parseInt(h)];
    else {
        return parseInt((arr[h] + arr[h-1]) / 2);
    }
}

export function getMean(arr) {
    return parseInt(arr.reduce((partialSum, a) => partialSum + a, 0) / arr.length);
}


export default function useTimeout(callback, delay) {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef();
    useEffect(() => {
        callbackRef.current = callback
    }, [callback]);
    const set = useCallback(() => {
        timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
    }, [delay]);
    const clear = useCallback(() => {
        timeoutRef.current && clearTimeout(timeoutRef.current)
    }, []);
    useEffect(() => {
        set();
        return clear
    }, [delay, set, clear]);
    const reset = useCallback(() => {
        clear();
        set()
    }, [clear, set]);
    return { reset, clear }
}


export function convertUTCDateToLocalDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
}

export function convertDurationToText(e) {
    const h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');
    if (h !== "00") return h + ':' + m + ':' + s;
    return m + ":" + s;
}

export function colorCustomRange(range, colorBefore, colorAfter) {
    range.addEventListener("input", (event) => {
        range.style.background = `linear-gradient(to right, ${colorBefore}, ${colorBefore} ${event.target.value}%, ${colorAfter})`
    })

}
