import React, {useEffect, useState} from "react";
import {Chart} from "react-chartjs-2";
import {SGraph} from "./style";

export function DoubleGraph({dataS1, dataS2, showLabel=true, title=null}) {
    const graphColors = ['#0076FF', '#f45fff'];

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: title !== null,
                text: title,
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false
                },
            },
        }
    };

    const defaultData = {
        labels: [],
        datasets: [
            {
                label: "BPM",
                data: [],
                borderColor: graphColors[0],
                backgroundColor: graphColors[0],
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 4,
                yAxisID: 'y',
            },
            {
                label: "Энергичность",
                data: [],
                borderColor: graphColors[1],
                backgroundColor: graphColors[1],
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 4,
                yAxisID: 'y1',
            }
        ],
    };

    const [graphData, setGraphData] = useState(defaultData);
    const [dataIsSet, setDataIsSet] = useState(false);

    useEffect(() => {
        if (dataS1 && dataS2) {
            let graph_d1 = JSON.parse(dataS1);
            let graph_d2 = JSON.parse(dataS2);
            if (graph_d1.length !== graph_d2.length) return;
            const data = {...defaultData};
            data.labels = [];
            data.datasets[0].data = [];
            data.datasets[1].data = [];

            for (let i = 1; i <= graph_d1.length; i++) {
                data.datasets[0].data.push(graph_d1[i-1] === null ? NaN : graph_d1[i-1]);
                data.datasets[1].data.push(graph_d2[i-1] === null ? NaN : graph_d2[i-1]);
                data.labels.push(i);
            }
            setGraphData(data);
            setGraphData(prev => {
                setDataIsSet(true);
                return prev;
            })
        }
    }, [dataS1, dataS2]);

    return <SGraph>
        {dataIsSet && <div style={{width: '100%', height: '300px'}} >
            {showLabel && <p className="graph-title">Совмещенные графики BPM и Энергичности</p>}
                <Chart options={options} data={graphData} type={'line'}  />
            </div>}
    </SGraph>
}
