import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useInterval} from "react-interval-hook";

const SBackground = styled.div`
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    
    #background-content {
        background-repeat: no-repeat;
        background-size: cover;
        width: calc(100dvw + 280px);
        height:  calc(100dvh + 280px);
        top: -140px;
        left: -140px;
    
        background-position: center;
        position: fixed;
        ${props => (props.img && !props.hasVideo) ? `background-image: linear-gradient(rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)), url(${props.img}); filter: blur(70px);` : ""}
    }
    
    #video {
      position: fixed;
      right: 0;
      bottom: 0;
      min-width: 100dvw;
      min-height: 100dvh;
      opacity: 0;
      transition: 0.7s opacity;
    }
    
`;

export function Background({trackInfo, setHasVideo, hasVideo, currentTime, playerPaused, weakConnection, hasLyrics}) {
    const [videoUrl, setVideoUrl] = useState(null);

    const handleResize = () => {
        let v = document.getElementById("video");
        if (v) {
            let w = window.innerWidth;
            let h = window.innerHeight;
            let vw = v.getBoundingClientRect().width;
            let vh = v.getBoundingClientRect().height;
            v.style.bottom = ((h - vh) / 2) +  "px";
            v.style.right = ((w - vw) / 2) +  "px";
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        let v = document.getElementById("video");
        console.log("opacity fired", hasLyrics, hasVideo, v);
        if (v && hasLyrics && v.style.opacity === "1") {
            v.style.opacity = "0.9";
        } else if (v && !hasLyrics && v.style.opacity === "0.9") {
            v.style.opacity = "1";
        }
    }, [hasLyrics, hasVideo]);

    useEffect(() => {
        const video = document.getElementById("video");
        if (video) {
            if (video.paused && !playerPaused) video.play();
            if (!video.paused && playerPaused) video.pause();
        }
    }, [playerPaused]);

    useInterval(() => {
        const video = document.getElementById("video");
        if (video) {
            if (currentTime > video.duration * 1000 && currentTime > 5000) {
                removeVideo();
            }
            if (video.paused) return;
            if (Math.abs((video.currentTime * 1000) - currentTime) > 200) {
                document.getElementById("video").currentTime = currentTime / 1000;
            }
        }

    }, 300);

    const removeVideo = () => {
        return new Promise((resolve, reject) => {
            try {
                document.getElementById("video").style.opacity = 0;
                setTimeout(() => {
                    try {
                        document.getElementById("video").remove();
                    } catch (e) {
                    }
                    resolve();
                }, 700);
                setHasVideo(false);

            } catch (e) {
                resolve();
                setHasVideo(false);
            }
        });

    };

    function compareVideo() {
        return new Promise((resolve, reject) => {
            let video = document.getElementById("video");
            let w = video.videoWidth;
            let h = video.videoHeight;
            if (w === h) reject();
            else resolve();
        });

    }

    useEffect(() => {
        if (trackInfo.video !== videoUrl) {
            setVideoUrl(trackInfo.video);
        }
    }, [trackInfo]);

    useEffect(() => {
        if (videoUrl && !weakConnection) {
            console.log("upd");
            removeVideo().then(() => {
                const v = document.createElement("video");
                v.style.opacity = 0;
                let s = document.createElement("source");
                s.id = "source";
                v.id = "video";
                s.src = videoUrl;
                v.preload = "auto";
                v.muted = true;
                v.addEventListener('loadeddata', function() {
                    v.play();
                    setTimeout(() => {
                        compareVideo().then(() => {
                            v.style.opacity = hasLyrics ? "0.9" : "1";
                            setHasVideo(true);
                            handleResize();
                        }).catch(() => {
                            removeVideo();
                        });
                    }, 500);


                }, false);
                document.getElementsByClassName("background")[0].appendChild(v);
                document.getElementById("video").appendChild(s);
                v.load();
            });
        } else {
            console.log('remove');
            removeVideo();
        }
    }, [videoUrl]);


    return <SBackground className="background" img={trackInfo.cover} hasVideo={hasVideo}>
        <div id="background-content">
        </div>
    </SBackground>
}
