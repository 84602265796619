import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";

const SEnergy = styled.div`
    display: flex;
    align-items: center;
    max-width: 60px;
    width: 100%;
    margin-right: 30px;
    justify-content: center;
    
    ${props => props.isMobile ? "margin-right: 7px; max-width: 30px;" : ""}

    span {
        border-radius: 2px;
        width: 4px;
        height: 20px;
        background-color: #0076ff;
        margin-left: 3px;
        ${props => props.isMobile ? "height: 17px; margin-left: 2px; width: 3px;" : ""}
    }
    
    span.dark {
        background-color: #515151;
    }
   

`;

export function Energy({energy, isMobile=isMobileDevice()}) {
    const [bars, setBars] = useState([]);

    useEffect(() => {
        const newBars = [];
        for (let i = 1; i <= 5; i++) {
            newBars.push(<span className={i > (energy || 0) ? "dark" : "colored"} />)
        }
        setBars(newBars)
    }, [energy]);

    return <SEnergy isMobile={isMobile}>
        {bars}
    </SEnergy>;
}
