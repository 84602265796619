import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import Slider from '@appigram/react-rangeslider';
import {colorCustomRange, convertDurationToText} from "../../../../utils/utils";
import {VolumeSliderStyle} from "./style";
import {isTouchDevice} from "../../playerUtils";

const SProgressBarMobile = `
    .large-song-div {
        margin: 0 20px;
        width: calc(100% - 40px);
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
    }
    
    #first-song-layer {
        margin-top: 25px;
        
   }
   
   #playbutton img {
        width: 40px;
   }
   
   #volumebutton img {
        width: 30px;
        height: 30px;
   }
   
   #volume-slider {
        /* transform: rotate(270deg); */
        left: 16px;
        top: -220px;
        right: unset;
   }
   
   .large-song-container {
       margin-bottom: 10px;
   }
   
`;

const SProgressBar = styled.div`
    height: 100dvh;
    position: absolute;
    width: 100dvw;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    
    opacity: 1;
    
   .large-song-div {
        margin: 0 150px;
        width: calc(100% - 300px);
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
    }
    
   .large-song-container {
       width: 100%;
       margin-bottom: 50px;
       display: flex;
       align-items: center;
       
       font-size: 14px;
       flex-direction: column;
   }
   
   .large-song-progress-container {
        width: 100%;
   }
   
   #large-song-progress-line {
       height: 4px;
       width: 100%;
       background-color: #ffffff17;
       border-radius: 5px;
   }
   
   #large-song-progress-pointer {
        height: 4px;
        width: 0%;
        border-radius: 5px;
        background-color: #ffffffba;
        transition: 0.5s width;
   }
   
   .large-song-time {
        color: #ffffff50;
        width: 46px;
        font-weight: 400;
   }
   
   .large-song-layer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
   }
   
   #second-song-layer {
        gap: 14px;
   }
   
   #first-song-layer {
        margin-bottom: 15px;
        justify-content: space-between;
   }
   
   #playbutton img {
        width: 30px;
        filter: brightness(0) invert(1);
        opacity: 0.8;
        cursor: pointer;
   }
   
   #volumebutton {
        width: 40px;
        text-align: center;
        
        position: relative;
   }
   
   #volumebutton img {
        height: 24px;
        filter: brightness(0) invert(1);
        opacity: 0.7;
        cursor: pointer;
   }
   
   .volume-slider-container {
        position: absolute;
        right: -162px;
        top: 10px;
        width: 150px;
        opacity: 0;
        transition: 0.2s opacity;
   }
   
   .volume-slider-container input {
        margin: 0;
        padding: 0;
   }
   
   ${VolumeSliderStyle}
   
   ${props => props.isMobile ? SProgressBarMobile : ""}
`;

export function ProgressBar({trackInfo, currentTime, isPaused, togglePlaying, volume, setVolume, isMobile=isTouchDevice()}) {
    const toggleSlider = () => {
        const node = document.getElementById("volume-slider");
        if (node) {
            if (node.style.opacity === "1") {
                node.style.opacity = "0";
            } else {
                node.style.opacity = "1";
            }
        }
    };

    const controlsLayer = <div className="large-song-layer stopMotionFading" id="first-song-layer">
        <div id="volumebutton" className="motionShowing stopMotionFading">
            <img src="/icons/volume-up.png" id="volume-button" className="stopMotionFading" onClick={toggleSlider} />
            <div className="volume-slider-container" id="volume-slider">
                <Slider
                    value={parseInt(volume * 100)}
                    orientation={isMobile ? "vertical" : "horizontal"}
                    step={1}
                    max={100}
                    min={0}
                    reverse={false}
                    tooltip={false}
                    onChange={(a) => {setVolume(a / 100)}}
                />

            </div>
        </div>
        <div id="playbutton" className="motionShowing" onClick={togglePlaying}>
            <img src={isPaused ?  "/icons/play_48.svg" : "/icons/pause_48.svg"} className="stopMotionFading" />
        </div>
        <div className="first-layer-side">
            <div style={{width: "30px", height: "30px"}}></div>
        </div>
    </div>;


    return <SProgressBar isMobile={isMobile}>
        <div className="large-song-div">
            <div className="large-song-container">
                {!isMobile && controlsLayer}
                <div className="large-song-layer" id="second-song-layer">
                    <div className="large-song-time" id="large-song-time-left">
                        {convertDurationToText(currentTime / 1000)}
                    </div>
                    <div className="large-song-progress-container">
                        <div id="large-song-progress-line">
                            <div id="large-song-progress-pointer" />
                        </div>
                    </div>
                    <div className="large-song-time" id="large-song-time-right">
                        {convertDurationToText(trackInfo.duration)}
                    </div>
                </div>
                {isMobile && controlsLayer}
            </div>
            <div className="large-song-time">


            </div>
        </div>
    </SProgressBar>
}
