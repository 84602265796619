import {isMobileDevice} from "../../../hooks/mobile/isMobile";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {Header} from "../../../components/Header";
import {Container} from "../../../components/Container";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {BackButton} from "../../../components/BackButton";
import {Checkbox} from "../../../components/Checkbox";
import {Graph} from "./Graph";
import CanvasDraw from "@win11react/react-canvas-draw";
import {DoubleGraph} from "./Graph/doubleGraph";
import {getMean, getMedian} from "../../../utils/utils";
import {PlaylistStats} from "./PlaylistStats";
import {BlockingBanner} from "../../../components/BlockingBanner";
import {DrawGraphModal} from "../../../modals/DrawGraphModal";
import {Button} from "../../../components/Button";
import styled from "styled-components";
import {SPlaylistStats} from "./PlaylistStats/style";
import {Track} from "../../../components/Track";
import {BlockSide} from "../../../components/Block/BlockSide";
import {Dropdown} from "../../../components/Dropdown";
import {Bar} from "../../../components/Bar";
import {FloatingContainer} from "../../../components/FloatingContainer";
import {Cooldowns} from "../../RoomPage/PlayingPage/components/Cooldowns";

const api = new API();

const SDiv = styled.div`
    .change-checkbox-container {
        display: flex;
        justify-content: space-between;
       
    }
    
    .graph-container {
         margin-bottom: 76px;
    }
`;

export function PlaylistSettingsPage({isMobile = isMobileDevice()}) {
    const [playlist, setPlaylist] = useState({});
    const {room_hash, playlist_id} = useParams();
    const [useBpm, setUseBpm] = useState(false);
    const [useEnergy, setUseEnergy] = useState(false);
    const [trackData, setTrackData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [canGraph, setCanGraph] = useState(false);
    const [graphFromEnd, setGraphFromEnd] = useState(false);
    const [energyPriority, setEnergyPriority] = useState(1);
    const [bpmPriority, setBpmPriority] = useState(1);


    const [startValue, setStartValue] = useState(0);
    const [stopValue, setStopValue] = useState(100);
    const [length, setLength] = useState(100);
    const [type, setType] = useState("bpm");

    const [topAdded, setTopAdded] = useState([]);
    const [topVoted, setTopVoted] = useState([]);
    const [topSkipped, setTopSkipped] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const stats_arrays = [
            [playlist.stats?.top_added_tracks || [], setTopAdded],
            [playlist.stats?.top_voted_tracks || [], setTopVoted],
            [playlist.stats?.top_skipped_tracks || [], setTopSkipped]
        ];
        for (const sa of stats_arrays) {
            const res = [];
            for (const tr of sa[0]) {
                res.push(<Track key={tr.identifier} artist={tr.artist} title={tr.title} tid={tr.identifier} cover={tr.cover} identifier={tr.identifier} bpm={tr.bpm} energy={tr.energy} />);
            }
            sa[1]([...res]);
        }

    }, [playlist]);

    const openDraw = (startV, stopV, l, t) => {
        setStartValue(startV);
        setStopValue(stopV);
        setLength(l);
        setType(t);
        setModalOpen(true);
    };

    const startAnalysis = () => {
        setLoading(true);
        api.startAnalysis(room_hash, playlist_id).finally(() => {
            setLoading(false);
        });
    };

    const navigate = useNavigate();

    const calcTrackData = (tracks) => {
        // todo: обработать случаи, когда нет проанализированных треков
        const td = {
            start_bpm: 1000,
            end_bpm: 0,
            start_energy: 10,
            end_energy: 0,
            count: 0,
            median_bpm: 0,
            mean_bpm: 0,
            median_energy: 0,
            mean_energy: 0
        };
        const bpm_arr = [];
        const energy_arr = [];
        for (let i = 0; i < tracks.length; i++) {
            let _bpm = tracks[i].bpm;
            let _nrg = tracks[i].energy;
            if (_bpm) {
                if (_bpm < td.start_bpm) td.start_bpm = _bpm;
                if (_bpm > td.end_bpm) td.end_bpm = _bpm;
                bpm_arr.push(_bpm);
            }
            if (_nrg) {
                if (_nrg < td.start_energy) td.start_energy = _nrg;
                if (_nrg > td.end_energy) td.end_energy = _nrg;
                energy_arr.push(_nrg);
            }
        }
        td.median_bpm = getMedian(bpm_arr);
        td.median_energy = getMedian(energy_arr);
        td.mean_bpm = getMean(bpm_arr);
        td.mean_energy = getMean(energy_arr);

        td.count = tracks.length;

        if (bpm_arr.length > 2 && energy_arr.length > 2) {
            setCanGraph(true);
        }

        setTrackData(td);
    };

    const drawGraphCallback = (data, t) => {
        let start_value = t === "bpm" ? trackData.start_bpm : trackData.start_energy;
        let stop_value = t === "bpm" ? trackData.end_bpm : trackData.end_energy;
        setModalOpen(false);
        api.saveGraph(room_hash, playlist_id, data, trackData.count, start_value, stop_value, t).then(() => {
            loadPlaylist();
        });
    };

    const loadPlaylist = () => {
        api.getPlaylist(room_hash, playlist_id, true).then((resp) => {
            setPlaylist(resp);
            setUseBpm(resp.use_bpm);
            setUseEnergy(resp.use_energy);
            setBpmPriority(resp.bpm_priority);
            setEnergyPriority(resp.energy_priority);
            setGraphFromEnd(resp.graph_from_end);
            calcTrackData(resp.tracks);
        })
    };

    useEffect(() => {
       if (playlist.id) {
           api.savePlaylistParams(room_hash, playlist_id, {
               "use_bpm": useBpm,
               "use_energy": useEnergy,
               "bpm_priority": bpmPriority,
               "energy_priority": energyPriority,
               "from_end": graphFromEnd
           })
       }
    }, [useEnergy, useBpm, bpmPriority, energyPriority, graphFromEnd]);

    useEffect(() => {
        loadPlaylist();
    }, []);

    return <div>
        <Header  />
        <Container paddingTop={'0px'} style={{marginBottom: isMobile ? "55px" : "0"}}>
            <SDiv>
                {!isMobile && <BackButton backPath={'/room/'+room_hash+'/playlist/'+playlist_id} />}
                <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Настройки плейлиста {playlist.name}</h1>
                <Block smallPadding={isMobile}>
                    <div>
                        <PlaylistStats trackData={trackData} playlist={playlist} />
                    </div>
                </Block>
                <Block smallPadding={isMobile}>
                    <div>
                        <h3>Статистика последней сессии</h3>
                        <SPlaylistStats isMobile={isMobile}>

                            <p>Количество прослушиваний</p>
                            <div>
                                <div>{playlist.stats?.last_listens} ({playlist.stats?.last_listens_percentage}%)</div>
                            </div>

                            <p>Количество голосов за пропуск</p>
                            <div>
                                <div>{playlist.stats?.last_skips_count} ({playlist.stats?.last_skips_percentage}%)</div>
                            </div>

                            <p>Количество добавленных треков</p>
                            <div>
                                <div>{playlist.stats?.last_add_count} ({playlist.stats?.last_add_count_percentage}%)</div>
                            </div>

                        </SPlaylistStats>
                    </div>
                </Block>
                <Block smallPadding={isMobile}>
                    <div style={{width: '100%'}}>
                        <div className="change-checkbox-container">
                            <Checkbox disabled={!canGraph} defaultChecked={playlist.use_bpm} name="bpm" onChange={setUseBpm} style={{marginLeft: "7px"}}>Использовать BPM</Checkbox>
                            <Button isPrimary={false} isRich={true} size="m" disabled={!useBpm} onClick={() => {
                                openDraw(trackData.start_bpm, trackData.end_bpm, trackData.count, "bpm");
                            }}>Изменить график</Button>
                        </div>

                        <div className="graph-container" style={{width: "100%", position: 'relative'}}>
                            <BlockingBanner isActive={useBpm} />
                            <Graph graphString={playlist.bpm_graph} startValue={trackData.start_bpm} stopValue={trackData.end_bpm} length={trackData.count} type='bpm' onOpen={openDraw} />
                        </div>

                        <div className="change-checkbox-container">
                            <Checkbox disabled={!canGraph} defaultChecked={playlist.use_energy} isPrimary={true} name="energy" onChange={setUseEnergy} style={{marginLeft: "7px"}}>Использовать Энергичность</Checkbox>
                            <Button isPrimary={true} isRich={true} size="m" disabled={!useEnergy} onClick={() => {
                                openDraw(trackData.start_energy, trackData.end_energy, trackData.count, "energy");
                            }}>Изменить график</Button>
                        </div>

                        <div className="graph-container" style={{width: "100%", position: 'relative'}}>
                            <BlockingBanner isActive={useEnergy} />
                            <Graph graphString={playlist.energy_graph} startValue={trackData.start_energy} stopValue={trackData.end_energy} length={trackData.count} type='energy' onOpen={openDraw} />
                        </div>

                        {(playlist.energy_graph && playlist.bpm_graph) && (
                            <div className="graph-container" style={{width: "100%"}}>
                                <DoubleGraph dataS1={playlist.bpm_graph} dataS2={playlist.energy_graph} showLabel={true} />
                            </div>
                        )}


                    </div>
                </Block>

                <Block smallPadding={isMobile}>
                    <div>
                        <SPlaylistStats isMobile={isMobile}>
                            <p><Checkbox defaultChecked={playlist.graph_from_end} name="from_end" onChange={setGraphFromEnd}>Заполнять графики с конца</Checkbox></p>
                            <div>
                                <div></div>
                            </div>

                            <p>Приоритет BPM</p>
                            <div>
                                <div>
                                    <Dropdown options={[
                                        {'value': 1, 'label': 1},
                                        {'value': 2, 'label': 2},
                                        {'value': 3, 'label': 3},
                                    ]} value={bpmPriority} onChange={setBpmPriority} />
                                </div>
                            </div>

                            <p>Приоритет Энергичности</p>
                            <div>
                                <div>
                                    <Dropdown options={[
                                        {'value': 1, 'label': 1},
                                        {'value': 2, 'label': 2},
                                        {'value': 3, 'label': 3},
                                    ]} value={energyPriority} onChange={setEnergyPriority} />
                                </div>
                            </div>

                        </SPlaylistStats>
                    </div>
                </Block>

                {topAdded.length ? <Block smallPadding={isMobile}>
                    <BlockSide col={true}>
                        <h3 className="title">Эти треки предлагают чаще всего</h3>
                        {topAdded}
                    </BlockSide>
                </Block> : null}

                {topVoted.length ? <Block smallPadding={isMobile}>
                    <h3></h3>
                    <BlockSide col={true}>
                        <h3 className="title">Эти треки поднимают чаще всего</h3>
                        {topVoted}
                    </BlockSide>
                </Block> : null}

                {topSkipped.length ? <Block smallPadding={isMobile}>

                    <BlockSide col={true}>
                        <h3 className="title">За пропуск этих треков голосуют чаще всего</h3>
                        {topSkipped}
                    </BlockSide>
                </Block> : null}

                {!isMobile && <FloatingContainer>
                    <Button size="m" onClick={startAnalysis} isLoading={loading}>Запустить анализ</Button>
                </FloatingContainer>}

                {isMobile && (
                    <Bar buttons={[
                        {text: "Назад", icon: "/icons/arrow_turn.svg", onClick: () => window.location.href = '/room/'+room_hash+'/playlist/'+playlist_id},
                       {text: "Запустить анализ", icon: "/icons/stars.svg", onClick: startAnalysis, isLoading: loading}
                    ]} />
                )}


                <DrawGraphModal callback={drawGraphCallback} isOpen={modalOpen} setOpen={setModalOpen} startValue={startValue} stopValue={stopValue} type={type} length={length} label={type === "bpm" ? "BPM" :  "Энергичности"} />
            </SDiv>
        </Container>
    </div>
}
