import React from "react";
import styled from 'styled-components';
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SBody = styled.div`
    position: absolute;
    top: 100px;
    right: 20px;
`;

const SContainer = styled.div`
    display: flex;
    width: 15%;
    flex-direction: column;
    
    > div {
        margin-bottom: 16px;
    }
`;

const SMobileBody = styled.div`
    position: fixed;
    bottom: 0px;
    width: calc(100% - 40px);
`;

const SMobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    > div {
        margin-bottom: 16px;
    }
`;

export function FloatingContainer({children, isMobile = isMobileDevice(), innerRef}) {
    if (isMobile) {
        return (
            <SMobileBody ref={innerRef}>
                <SMobileContainer>{children}</SMobileContainer>
            </SMobileBody>
        )
    } else {
        return (
            <SBody>
                <SContainer>{children}</SContainer>
            </SBody>
        )
    }
}
