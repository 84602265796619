import styled from "styled-components";

export const SGraph = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 25px;
    
    .graph-title {
        margin-bottom: 15px;
    }
`;

export const SNoGraph = styled.div`
    width: calc(100% - 30px);
    min-height: 150px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://img.freepik.com/premium-photo/background-abstraction-investment-black-chart-black-screen_816702-1605.jpg");
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 13px 0px black;
    cursor: pointer;
    
    h3 {
        font-weight: 400;
        font-size: 16px;
    }
`;
