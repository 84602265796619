import {Button} from "../../../../components/Button";
import React from "react";
import {CookieParameter} from "../cookieParameter";

export function NumberField({name, value, setValue, style}) {

    const setV = (v) => {
        if (isNaN(v) || v === undefined) v = 0;
        setValue(v)
    };

    const substract = (f) => {
        f(prev => Math.max(parseInt(prev)-1, 0));
    };

    const add = (f) => {
        f(prev => Math.min(parseInt(prev)+1, 10000));
    };

    return <div style={{display: "flex", alignItems: "center", gap: "15px", marginTop: "25px", ...style}}>
        <CookieParameter name={name} value={value} setValue={setV} mutateFn={parseInt} />
        <Button isRich={false} isPrimary={false} size={"s"} onClick={() => substract(setValue)}>-</Button>
        <input type="number" id={name+'_input'} value={value} onChange={(e) => setV(e.target.value)} min={0} max={10000} /> мс.
        <Button isRich={false} isPrimary={false} size={"s"} onClick={() => add(setValue)}>+</Button>
    </div>
}
