import {useNavigate} from "react-router";
import {styled} from "styled-components";
import React from "react";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SButton = styled.h2`
    cursor: pointer;
    font-weight: 400;
    font-size: 22px;
    margin-top: 15px;
    
    ${props => props.isMobile ? "font-size: 16px; margin-top: 8px; margin-bottom: 0px;" : ""}
`;

export function BackButton({backPath, isMobile=isMobileDevice()}) {
    const navigate = useNavigate();

    const go = () => {
        navigate(backPath)
    };

    return <SButton onClick={go} isMobile={isMobile}>← Назад</SButton>
}
