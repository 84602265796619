import React, {useEffect, useState} from "react";
import {Header} from "../../../components/Header";
import {Container} from "../../../components/Container";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {BackButton} from "../../../components/BackButton";
import {BlockSide} from "../../../components/Block/BlockSide";
import {SPlaylistStats} from "../../PlaylistPage/PlaylistSettingsPage/PlaylistStats/style";
import {DoubleGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/doubleGraph";
import {ActivityGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/activityGraph";

const api = new API();

export function SessionPage({isMobile=isMobileDevice()}) {
    const navigate = useNavigate();
    const [session, setSession] = useState({});

    const {room_hash, session_id} = useParams();

    const update = () => {
        api.getSession(room_hash, session_id).then(resp => {
            setSession(resp);
        });
    };

    useEffect(() => {
        update()
    }, []);


    return <div>
        <Header  />
        <Container paddingTop="0px">

            <BackButton backPath={`/room/${room_hash}/stats`} />
            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Статистика сессии</h1>

            <Block smallPadding={isMobile}>
                <BlockSide col={true}>
                    <SPlaylistStats isMobile={isMobile}>

                        <p>Количество добавленных треков</p>
                        <div>
                            <div>{session?.tracks_added}</div>
                        </div>

                        <p>Количество голосов за пропуск</p>
                        <div>
                            <div>{session?.skip_votes}</div>
                        </div>

                        <p>Количество апвоутов</p>
                        <div>
                            <div>{session?.upvotes}</div>
                        </div>

                        <p>Количество прослушанных треков</p>
                        <div>
                            <div>{session?.tracks_listened}</div>
                        </div>

                    </SPlaylistStats>
                </BlockSide>
            </Block>

            <Block smallPadding={isMobile}>
                <DoubleGraph dataS1={session.bpm_graph} dataS2={session.energy_graph} showLabel={false} title="Показатели энергичности и BPM всех прослушанных треков в рамках сессии" />
            </Block>

            <Block smallPadding={isMobile}>
                <ActivityGraph data={session.activity_graphs} title="Показатели активности пользователей в рамках сессии" />
            </Block>

        </Container>
    </div>
}
