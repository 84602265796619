import {useEffect, useState} from "react";
import Cookies from "js-cookie";

export function CookieParameter({name, setValue, value, mutateFn = (v) => v}) {
    const [inited, setInited] = useState(false);


    useEffect(() => {
        setValue(mutateFn(Cookies.get(name, value)));
        setInited(true);
    }, []);

    useEffect(() => {
        if (inited) Cookies.set(name, value);
    }, [value]);

}
