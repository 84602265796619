import styled from 'styled-components';
import React from "react";
import {isMobileDevice} from "../../hooks/mobile/isMobile";
import {Bpm} from "./bpm";
import {Energy} from "./energy";

const STrack = styled.div`
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.bg_active};
    width: ${props => props.isMobile ? "calc(100% - 24px)" : "calc(100% - 24px)"};
    
    ${props => props.isMobile ? "margin-bottom: 4px;" : "margin-bottom: 10px;"}
    padding: ${props => props.isMobile ? "10px" : "8px 10px"};
    
    ${({ selectable }) => selectable ? "cursor: pointer;" : ""}
    
    img {
        width: 48px;
        height: 48px;
        border-radius: 5px;
        ${props => props.isMobile ? "width: 38px; height: 38px; border-radius: 2px;" : ""}
    }
    
    > div {
        margin-right: 15px;
        
    }
    
    > div:last-child {
        ${props => props.isMobile ? "margin-right: 5px;" : ""}
    }
    
    ${props => props.isMobile ? " border-radius: 4px;" : ""}
`;

const STrackTitle = styled.div`
    display: flex;
    flex-direction: column;
    height: 48px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${props => props.isMobile ? "height: 38px;" : ""}
`;

const STrackButtons = styled.div`
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
`;

const SUpvote = styled.div`
    display: flex;
    align-items: center;

    span {
        color: #ffffff55;
        cursor: default;
        font-weight: 600;
        ${props => props.isMobile ? "font-size: 14px;" : ""}
    }
    
    span.cross {
        font-size: 28px;
        padding-bottom: 5px;
        cursor: pointer;
        ${props => props.isMobile ? "font-size: 22px;" : ""}
    }
    
    img {
        height: 24px;
        width: 21.66px;
        margin-left: 15px;
        cursor: pointer;
        ${props => props.isMobile ? "height: 16px; width: 14px; margin-left: 8px;" : ""}
    }

`;

export function Track({
                          tid, identifier, artist, title, cover, votes=null, onDelete=null, onSelect=null, onUpvote=null,
                          isMobile=isMobileDevice(), bpm=null, energy=null
                      }) {
    return <STrack isMobile={isMobile} selectable={!!onSelect} onClick={() => {
        if (onSelect) onSelect(tid);
    }}>
        <div style={{display: "flex", }}>
            <img src={cover || "/icons/default_cover.png"} />
        </div>
        <STrackTitle isMobile={isMobile}>
            <span style={{fontSize: isMobile ? '14px' : '16px'}}>{title}</span>
            <div style={{color: "rgba(255,255,255,0.7)", fontSize: isMobile ? '12px' : '14px'}}>{artist}</div>
        </STrackTitle>
        <STrackButtons>
            {bpm !== null ? <Bpm bpm={bpm} /> : null}
            {energy !== null ? <Energy energy={energy} /> : null}
            {votes !== null ? <SUpvote isMobile={isMobile}>
                <span>{votes}</span>
                <img onClick={() => onUpvote(tid)} src="/upvote.png" />
            </SUpvote> : null}
            {onDelete !== null ? <SUpvote isMobile={isMobile}>
                <span onClick={() => {onDelete(tid)}} className="cross">&times;</span>
            </SUpvote> : null}
        </STrackButtons>

    </STrack>
}
