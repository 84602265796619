import {useCallback, useEffect, useState} from "react";
import {useInterval} from "react-interval-hook";
import {isTouchDevice} from "../../playerUtils";

export function MouseObserver({isMobile=isTouchDevice()}) {
    const [lastMoved, setLastMoved] = useState(new Date());
    const [lastTarget, setLastTarget] = useState(null);
    const waitingTime = 1000;

    const fade = (opacity) => {
        const els = document.getElementsByClassName("motionShowing");
        for (const el of els) {
            el.style.opacity = opacity;

        }
    };


    const onMouseMove = (event) => {
        setLastMoved(new Date());
        setLastTarget(event.target);
    };

    const recursiveFindStopMotion = (t, step) => {
        if (step === 6) {
            return;
        }

        if (!t) return;
        if (t && t.classList && t.classList.contains("stopMotionFading")) return true;
        return recursiveFindStopMotion(t.parentNode, step+1);
    };

    const observer = useInterval(() => {
        const now = new Date();
        if (lastMoved && now - lastMoved > waitingTime) {
            if (lastTarget && !recursiveFindStopMotion(lastTarget, 1)) {
                fade(0);
            }

        } else {
            fade(1);
        }
    }, 100, {autoStart: true});


    useEffect(() => {
        if (isMobile)  {
            fade(1);
            observer.stop();
        } else {
            document.addEventListener("mousemove", onMouseMove);
        }
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            observer.stop();
        }
    }, []);

}
