import styled from "styled-components";
import React, {useEffect, useState} from "react";

const SnoMedia = styled.div`
    height: 100dvh;
    position: absolute;
    width: 100dvw;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: ${props => props.show ? "flex" : "none"};
    
    
    .n-center {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: white;
        height: 100dvh;
    }
    
    #n-song-artist, .n-song-progress-container {
        margin-top: 10px;
    }
    
    .n-song-progress-container {
        margin-top: 13px;
        width: 350px;
    }
    
    #n-song-progress-line {
        width: 350px;
        
    }
    
    #n-song-image {
        width: 400px;
        border-radius: 15px;
    }
    
    #n-song-progress-pointer {
         width: 0%;
    }
`;

export function NoMediaScreen({trackInfo, hasRich}) {
    return <SnoMedia show={!hasRich}>
        <div className="n-center">
            <img id="n-song-image" src={trackInfo.cover || ""} />
            <span id="n-song-artist">{trackInfo.artist || "Загрузка..."}</span>
            <span id="n-song-title">{trackInfo.title}</span>
            <div className="n-song-progress-container">
                <div id="n-song-progress-line">
                    <div id="n-song-progress-pointer"></div>
                </div>
            </div>
        </div>
    </SnoMedia>
}
