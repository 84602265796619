import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {isTouchDevice} from "../../playerUtils";

const SscreenMobile = `
    .song-info-container {
            margin-top: 65px;     
        }
`;

const OldSScreen = styled.div`
    position: absolute;
    width: 100dvw;
    height: calc(100dvh - 30px);
    display: ${props => props.show ? "flex" : "none"};
    align-items: flex-end;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    
    .song-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
        background: #34343463;
        padding: 13px;
        border-radius: 15px;
    }
    
    #song-image {
        width: 100px;
        border-radius: 15px;
    }
    
    .song-left {
        height: 100px;
    }
    
    .song-right {
        margin-left: 20px;
        flex-grow: 2;
    }
    
    .song-data {
        font-size: 18px;
        font-family: Arial;
        display: flex;
        flex-direction: column;
        color: white;
        justify-content: flex-start;
        /* font-weight: 200; */
        height:  100%;
    }
    
    
    
    #song-votes {
        color: #ffffff30;
        float: right;
        font-size: 18px;
    }
    
    #song-progress-pointer {
        width: 0%;
    }
    
    
`;

const SScreen = styled.div`
    position: absolute;
    width: 100dvw;
    display: ${props => props.show ? "flex" : "none"};
    justify-content: center;
    align-content: center;
    overflow: hidden;
    
    .song-info-container {
        margin-top: 45px;
        text-align: center;
        z-index: 1;
        
    }
    
    #song-title {
        font-size: 38px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    
    #song-artist {
        font-size: 22px;
        font-weight: 300;
        opacity: 0.5;
        
    }
    
    ${props => props.isMobile ? SscreenMobile : ""}
`;

export function OldScreen({trackInfo, hasRich}) {
    return <SScreen show={hasRich}>
        <div className="bottom-center">
            <div className="song-info">
                <div className="song-left">
                    <img id="song-image" src={trackInfo.cover || ""}/>
                </div>
                <div className="song-right">
                    <div className="song-data">
                        <span id="song-artist">
                            <span id="song-artist-name">{trackInfo.artist || "Загрузка..."}</span>
                            <span id="song-votes">
                                {trackInfo.skip_target !== undefined ? `${trackInfo.skip_voted} / ${trackInfo.skip_target}` : ""}
                            </span>
                        </span>
                        <span id="song-title">{trackInfo.title}</span>

                        <div className="song-progress-container">
                            <div id="song-progress-line">
                                <div id="song-progress-pointer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </SScreen>
}


export function Screen({trackInfo, hasRich, isMobile=isTouchDevice()}) {
    return <SScreen show={hasRich || isMobile} isMobile={isMobile}>
        <div className="song-info-container">
            <div>
                <div id="song-title">{trackInfo.title || "Загрузка..."}</div>
            </div>
            <div>
                <div id="song-artist">{trackInfo.artist}</div>
            </div>

        </div>
    </SScreen>
}
