import styled from 'styled-components';
import React from "react";


const SButton = styled.div`
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: .26px;
    background-color: ${({ theme, is_primary, is_rich }) => is_rich ? (is_primary ? theme.colors.primary : theme.colors.secondary) : "#00000000"};
    ${({ theme, is_rich, is_primary }) => is_rich ? `border: 1px solid ${is_primary ? theme.colors.primary : theme.colors.secondary};` : `border: 1px solid ${theme.colors.input_color};`}
    border-radius: ${({ theme, size }) => theme.buttons[size].radius || theme.buttons.radius};
    font-size: ${({ theme, size }) => theme.buttons[size].font};
    font-weight: ${({ theme }) => theme.buttons.weight};
    width: ${({ theme, size }) => theme.buttons[size].width};
    height: ${({ theme, size }) => theme.buttons[size].height};
    outline: none;
    
    ${(props) => props.active ? "" : "background-color: #4b4b4b; border: 1px solid #4b4b4b;"}
    ${(props) => props.disabled ? "opacity: 0.4; cursor: default;" : ""}
    
    ${props => props.responsive ? "width: 100%;" : ""}
    
    transition: 0.3s opacity; 
    
`;

export function Button({children, onClick, isPrimary=true, isRich=true, size='l', active=true, disabled=false, responsive=false, style={}, isLoading=false}) {
    return <SButton style={style} responsive={responsive} active={active} disabled={disabled} onClick={() => disabled ? null : onClick()} is_primary={isPrimary} is_rich={isRich} size={size}>
        {!isLoading ? children : <img src={"/icons/spinner.gif"} className="button-spinner" />}
    </SButton>
}
