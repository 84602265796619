import styled from "styled-components";

const SPlayerMobilePage = `
    .menu-button img {
        width: 38px;
        opacity: 0.9;
        
        cursor: pointer;
    }
`;

export const SPlayerPage = styled.div`

    .make-white {
        filter: brightness(0) invert(1);
        opacity: 0.7;
    }
   
    .main {
        margin: 0;
        overflow: hidden;
        font-family: SF;
    }
    
    .motionShowing {
        transition: 0.2s opacity;
    }
    
    .song-progress-container {
        align-self: flex-start;
        flex-grow: 4;
        display: flex;
        align-items: flex-end;
    }
    
    #song-progress-line, #n-song-progress-line {
        height: 4px;
        width: 400px;
        background-color: #ffffff17;
        border-radius: 5px;
    }
    
    #song-progress-pointer, #n-song-progress-pointer {
        height: 4px;
        width: 0%;
        border-radius: 5px;
        background-color: #ffffffba;
        transition: 0.5s width;
    }
    
    #song-artist, #n-song-artist {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    
    .logo {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    
    .logo img {
        opacity: 0.3;
        height: 35px;
    }
    
    .logo-content {
        flex-direction: column;
        align-items: stretch;
    }
    
    .logo-content > button {
        height: 30px;
        margin-bottom: 10px;
        color: white;
        background: #0076ff;
        border-radius: 5px;
        outline: none;
        border: none;
        font-family: Arial;
    }
    
    .menu-button img {
        width: 32px;
        opacity: 0.9;
        
        cursor: pointer;
    }
    
    .menu-button {
        position: absolute;
        top: 10px;
        left: 14px;
        z-index: 2;
    }
    
    .menu-container {
        transition: opacity 0.2s;
        z-index: 2;
    }
    
    .menu-content {
        border-radius: 10px;
        padding: 15px 18px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 12px;
        font-weight: 300;
        background: rgb(255 255 255 / 6%);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10.9px);
        -webkit-backdrop-filter: blur(10.9px);
    }
    
    .menu-content > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 25px;
    }
    
    .menu-content .checkbox-container {
        margin: 0;
    }
    
    .menu-content .checkbox-container span {
        margin-top: 0!important;
    }
    
    
    ${props => props.isMobile ? SPlayerMobilePage : ""}
`;
