import {SPlayerPage} from "./style";
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {API} from "../../api";
import {Background} from "./components/background";
import {Lyrics} from "./components/lyrics";
import {Screen} from "./components/screen";
import {NoMediaScreen} from "./components/noMediaScreen";
import {Player} from "./components/player";
import {useInterval} from "react-interval-hook";
import {applyLyrics} from "./components/lyrics/utils";
import useTimeout from "../../utils/utils";
import {getSocket} from "./utils/ws";
import {Notifications} from "./components/notifications";
import {Button} from "../../components/Button";
import Cookies from "js-cookie";
import {Checkbox} from "../../components/Checkbox";
import {NumberField} from "./components/numberField";
import {CookieParameter} from "./components/cookieParameter";
import {ProgressBar} from "./components/ProgressBar";
import {MouseObserver} from "./components/mouseObserver";
import {Dropdown} from "../../components/Dropdown";
import {useNtpAlgorithm} from "./hooks/useNtpAlgorithm";
import {isTouchDevice} from "./playerUtils";

const api = new API();

const state = {
    apply_room_data_reasons: {ws: 0, track_info: 0}
};

export function PlayerPage({isMobile=isTouchDevice()}) {
    const [currentTrackInfo, setCurrentTrackInfo] = useState({});
    const [newTrackInfo, setNewTrackInfo] = useState({});
    const [hasRich, setHasRich] = useState(false);
    const [hasLyrics, setHasLyrics] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(1);
    const [showControls, setShowControls] = useState(false);

    const [volume, setVolume] = useState(0.1);
    const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
    const [playerNode1, setPlayerNode1] = useState(null);
    const [playerNode2, setPlayerNode2] = useState(null);
    const [crossfading, setCrossfading] = useState(false);
    const [playerTimeSourceIndex, setPlayerTimeSourceIndex] = useState(1);
    const [playerPaused, setPlayerPaused] = useState(true);

    const [preloading, setPreloading] = useState(false);
    const [cancelPreloading, setCancelPreloading] = useState(false);
    const [preloadingTimeoutDelay, setPreloadingTimeoutDelay] =  useState(0);

    const [websocket, setWebsocket] = useState(null);
    const [lastNotification, setLastNotification] = useState({});

    const [audioDelay, setAudioDelay] = useState(0);
    const [is_ws_host, setIsWsHost] = useState(false);
    const [roomData, setRoomData] = useState(null);
    const [initialRoomSetup, setInitialRoomSetup] = useState(false);

    const [weakConnection, setWeakConnection] = useState(false);
    const [updateTimeInterval, setUpdateTimeInterval] = useState(1000);
    const [hqVideo, setHqVideo] = useState(null);
    const [videoQuality, setVideoQuality] = useState("1080");

    const [applyDataOnPlay, setApplyDataOnPlay] = useState(true);

    const {room_hash} = useParams();
    const navigate = useNavigate();

    const ntpService = useNtpAlgorithm(websocket);


    const toggleControls = () => {
        const node = document.getElementById("menu-container");
        if (node) {
            if (node.style.opacity === "1") {
                node.style.opacity = "0";
                setTimeout(() => {
                    node.style.display = "none";
                }, 300);
            } else {
                node.style.display = "block";
                setTimeout(() => {
                    node.style.opacity = "1";
                }, 50);
            }
        }
    };

    const setPlayerTime = (t) => {
        setCurrentPlayerTime(t + audioDelay);
    };

    const setupWebsocket = (room_hash) => {
        const ws = getSocket(room_hash);

        ws.onmessage = (event) => {
            console.log(event.data);
            const d = JSON.parse(event.data);
            if (d.type === "hello") {
                setIsWsHost(!!d.data.is_host)
            } else if (d.type === "event") {
                setLastNotification(d);
            } else if (d.type === "room") {
                applyRoomData(d, "ws");
            }
        };

        ws.onopen = () => {
            ws.send(JSON.stringify({
                type: "hello",
                is_host: true
            }));

            websocketInited(ws);

            setWebsocket(prev => {
                if (prev) {
                    try {
                        prev.close()
                    } catch (e) {}
                }
                return ws;
            });
        }

        ws.onclose = function(e) {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function() {
                setupWebsocket(room_hash);
            }, 400);
        };

        ws.onerror = function(err) {
            console.error('Socket encountered error: ', err.message, 'Closing socket');
            ws.close();
        };
    }


    useEffect(() => {
        if (room_hash) {
            setupWebsocket(room_hash);

            api.getRoom(room_hash).then(res => {
                setHqVideo(res.prefer_hq);
            });

        }
        return () => {
            if (websocket) websocket.close();
        }
    }, [room_hash]);

    const getPlayerNode = () => {
        return currentPlayerIndex === 1 ? playerNode1 : playerNode2;
    };

    const playAudio = () => {
        if (getPlayerNode()) {
            playerNode1.play();
        }
    };

    const checkAndDoNext = () => {
        api.getRoomStatus(room_hash).then(resp => {
            if (resp.current_track?.id !== newTrackInfo.id) {
                api.nextTrack(room_hash);
            }
        })
    };

    const websocketInited = (ws) => {

    };

    const applyRoomData = useCallback((d, reason) => {
        state.apply_room_data_reasons[reason] = 1;
        if (reason === "ws") state.apply_room_data_reasons.room_data = d;
        if (!initialRoomSetup && state.apply_room_data_reasons.track_info && state.apply_room_data_reasons.ws && !state.apply_room_data_reasons.entered) {
            state.apply_room_data_reasons.entered = 1;
            let room = state.apply_room_data_reasons.room_data.room;
            console.log(room, currentTrackInfo);
            setPlayerNode1(prev => {
                if (room.current_track_id === state.apply_room_data_reasons.track_info_data.id) {
                    if (room.is_paused !== playerPaused) {
                        console.log("toggle by apply");
                        togglePlaying();
                    }
                    console.log("NODE", room.current_player_time, room);
                    if (room.current_player_time) {
                        const node = prev;
                        console.log("NODE", node);
                        if (node) {
                            const now = (new Date()).getTime() + ntpService.timeOffset; // compensate audio seek delay
                            const delta = now - room.last_updated_client_time;
                            const new_time = (room.current_player_time) / 1000;
                            console.log("TIME DIFF", node.currentTime - new_time);
                            node.currentTime = new_time + 0.05;
                            console.log("set time", new_time)
                        }
                    }
                }
                setInitialRoomSetup(true);


                return prev;
            })


        }

    }, [currentTrackInfo, playerNode1]);

    const update = () => {
        api.getRoomStatus(room_hash).then((resp) => {
            if (resp.current_track) {
                const info = {...resp.current_track, ...resp.current_track_rich, skip_target: resp.skip_target, skip_voted: resp.skip_voted, room_hash: room_hash};
                if (!crossfading && !preloading) {
                    if (!info.cover) info.cover = "/icons/default_cover_large.png";
                    if (!currentTrackInfo.id || currentTrackInfo.id === info.id) {
                        setCurrentTrackInfo(info);
                        state.apply_room_data_reasons.track_info_data = info;
                        applyRoomData({}, "track_info");
                        //setCurrentTrackInfo(prev => {
                        //    applyRoomData({}, "track_info");
                        //    return prev;
                        //});
                        setHasLyrics(!!resp.current_track_rich?.lyrics);
                    } else {
                        setNewTrackInfo(info);
                    }
                }


            }
            //else window.location.reload();
        });
    };

    const {reset: resetPreloadingTimeout} = useTimeout(() => {
        (currentPlayerIndex === 1 ? playerNode2 : playerNode1).play();
        checkAndDoNext();
        doCrossfade();
    }, preloadingTimeoutDelay);

    const updateUI = (ct=currentPlayerTime) => {
        let percentage = Math.min((currentPlayerTime/1000) / (currentTrackInfo.duration/100), 100);
        //document.getElementById("song-progress-pointer").style.width = percentage+"%";
        document.getElementById("n-song-progress-pointer").style.width = percentage+"%";
        document.getElementById("large-song-progress-pointer").style.width = percentage+"%";
        applyLyrics(currentTrackInfo.lyrics, ct);
    };

    const preloadTrack = () => {
        setPreloading(true);
        api.preloadTrack(room_hash).then(res => {
            console.log("preload", res);
            if (res.success && res.track.id) {
                setNewTrackInfo(res.track);
                const delay = Math.max(currentTrackInfo.duration*1000 - currentPlayerTime - 3000, 0);
                setPreloadingTimeoutDelay(delay);
                setPreloadingTimeoutDelay(prev => {
                    resetPreloadingTimeout();
                    return prev;
                });
            } else {
                setPreloading(false);
                setCancelPreloading(true);
            }

        })
    };

    useEffect(() => {
        updateUI(currentPlayerTime);
        const delta = weakConnection ? 12000 : 8000;
        if ((currentTrackInfo.duration*1000) - currentPlayerTime <= delta && !preloading && !cancelPreloading) {
            preloadTrack();
        }
    }, [currentPlayerTime]);

    useInterval(() => {
        update();
        ntpService.update();
        //sync();
    }, updateTimeInterval);

    useEffect(() => {
        update();

    }, []);

    useEffect(() => {
        setHasRich(hasVideo || hasLyrics);
    }, [hasVideo, hasLyrics]);

    useEffect(() => {
        console.log(weakConnection, "wq");
        if (weakConnection) setUpdateTimeInterval(3000);
        else setUpdateTimeInterval(1000);
    }, [weakConnection]);

    const doCrossfade = () => {
        (currentPlayerIndex === 1 ? playerNode2 : playerNode1).volume = 0;
        setCrossfading(true);
        crossfade.start();
        setTimeout(() => {
            setCurrentTrackInfo(newTrackInfo);
            setNewTrackInfo(prev => {});
            setPlayerTimeSourceIndex(currentPlayerIndex === 1 ? 2 : 1);
        }, 1500);
        setTimeout(() => {
            console.log("toggle cpi", currentPlayerIndex);
            setCurrentPlayerIndex(currentPlayerIndex === 1 ? 2 : 1);
            setCurrentPlayerIndex(prev => {
                setCrossfading(false);
                console.log("stopped cf");
                try {
                    const event = new CustomEvent("destroy", {playerIndex: prev === 1 ? 2 : 1});
                    (prev === 1 ? playerNode2 : playerNode1).dispatchEvent(event);
                } catch (e) {
                }
                setPreloading(false);
                setCancelPreloading(false);
                return prev;
            })

        }, 3100);
    };

    const crossfade = useInterval(() => {
        const next_node = currentPlayerIndex === 1 ? playerNode2 : playerNode1;
        const current_node = currentPlayerIndex === 1 ? playerNode1 : playerNode2;
        const speed = volume / (3000 / 50);
        let new_inc_vol = next_node.volume + speed;
        let new_dec_vol = current_node.volume - speed;
        if (new_inc_vol >= volume || new_dec_vol <= 0) {
            next_node.volume = volume;
            current_node.volume = 0;
            console.log("stop");
            crossfade.stop();
        } else {
            next_node.volume = new_inc_vol;
            current_node.volume = new_dec_vol;
        }
    }, 50, {autoStart: false});

    const togglePlaying = () => {
        console.log("toggle playing");
        const nodes = [playerNode1, playerNode2];
        for (const player of nodes) {
            if (player) {
                if (playerPaused) {
                    player.play();
                } else {
                    player.pause();
                }
            }
        }
        setPlayerPaused(!playerPaused);
    };

    useEffect(() => {
        if (!playerPaused && applyDataOnPlay) {
            applyRoomData({}, "track_info");
            setApplyDataOnPlay(false);
        }
    }, [playerPaused]);

    const sendSyncData = useInterval(() => {
        console.log("sync time prep", ntpService.timeOffset);
        websocket.send(JSON.stringify({
            type: "sync_time",
            ts: (new Date()).getTime() + ntpService.timeOffset,
            current_player_time: getPlayerNode().currentTime * 1000,
            track_id: currentTrackInfo.id,
            is_paused: playerPaused
        }));
    }, 1000, {autoStart: false});

    useEffect(() => {
        if (initialRoomSetup && is_ws_host) {
            sendSyncData.start();
        }
    }, [initialRoomSetup]);

    useEffect(() => {
        if (websocket) {
            const listener = window.addEventListener("playerReady", () => {
                console.log("player ready");
                websocket.send(JSON.stringify({type: "get_room"}));
            });

            return () => {
                window.removeEventListener("playerReady", listener);
            }
        }



    }, [websocket]);

    function toggleFullScreen() {
        if (!document.fullscreenElement &&    // alternative standard method
            !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }


    return <SPlayerPage isMobile={isMobile}>
        <div className="main">
            <Background trackInfo={currentTrackInfo} hasVideo={hasVideo} setHasVideo={setHasVideo} currentTime={currentPlayerTime} weakConnection={weakConnection} hasLyrics={hasLyrics} isPaused={playerPaused} videoQuality={videoQuality} />
            <Lyrics trackInfo={currentTrackInfo} setHasLyrics={setHasLyrics} />
            <Screen trackInfo={currentTrackInfo}  hasRich={hasRich} />
            <NoMediaScreen trackInfo={currentTrackInfo} hasRich={hasRich} />
            <ProgressBar trackInfo={currentTrackInfo} currentTime={currentPlayerTime} isPaused={playerPaused} togglePlaying={togglePlaying} volume={volume} setVolume={setVolume} />


            <div className="menu-button motionShowing stopMotionFading">
                <img src="/icons/menu_28.svg" className="make-white" onClick={toggleControls} />
                <div className="menu-container" id="menu-container" style={{opacity: "0", display: "none"}}>
                    <div className="menu-content">
                        <div>
                            Разница с сервером: {ntpService.timeOffset} мс.
                        </div>
                        <div>
                            <Checkbox name={"weak-connection"} onChange={setWeakConnection} defaultChecked={weakConnection}>Слабое соединение</Checkbox>
                        </div>
                        <div>
                            <div>
                                Макс. качество видео
                            </div>
                            <div>
                                <Dropdown options={[
                                    {value: "1080", label: "1080p"},
                                    {value: "720", label: "720p"},
                                    {value: "480", label: "480p"},
                                    {value: "disabled", label: "Без видео"}
                                ]} onChange={setVideoQuality} value={videoQuality} />
                            </div>
                        </div>
                        <div>
                            <Button isRich={false} responsive={true} size="xs" onClick={() => {
                                toggleFullScreen();
                            }}>Полноэкранный режим</Button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="dots">
                <div id="dot-1"></div>
                <div id="dot-2"></div>
            </div>
            <div className="logo" style={{position: "absolute", top: "5px", right: "5px", zIndex: "101", left: "unset"}}>
                <img src="/klzm.png"/>
                <div className="logo-content " style={{display: showControls ? "flex" : "none"}}>

                        <button onClick={playAudio}>Play</button>
                    <button onClick={() => {
                        navigate(`/view2/${room_hash}/delays`);
                    }}>Контроль задержек</button>
                    <NumberField style={{marginTop: '5px'}} name={"audio_delay"} value={audioDelay} setValue={setAudioDelay} />
                </div>

            </div>
        </div>

        <div id="players_container" style={{display: "none"}}>
            <Player trackInfo={currentTrackInfo} volume={volume} setTime={setPlayerTime} setNode={setPlayerNode1} setPaused={setPlayerPaused} playerIndex={1} currentIndex={currentPlayerIndex} newTrackInfo={newTrackInfo} crossfading={crossfading} doCrossfade={doCrossfade} preloading={preloading} playerTimeSourceIndex={playerTimeSourceIndex} />
            <Player trackInfo={currentTrackInfo} volume={volume} setTime={setPlayerTime} setNode={setPlayerNode2} setPaused={setPlayerPaused} playerIndex={2} currentIndex={currentPlayerIndex} newTrackInfo={newTrackInfo} crossfading={crossfading} doCrossfade={doCrossfade} preloading={preloading} playerTimeSourceIndex={playerTimeSourceIndex} />
        </div>
        {!isMobile && <Notifications lastNotification={lastNotification}/>}

        <CookieParameter name={"weak_connection"} value={weakConnection} setValue={setWeakConnection} mutateFn={v => v === "true"} />
        <MouseObserver />
    </SPlayerPage>
}
