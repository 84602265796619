import styled from "styled-components";

export const SPlayerPage = styled.div`
   
    .main {
        margin: 0;
        overflow: hidden;
        font-family: Arial;
    }
    
    .song-progress-container {
        align-self: flex-start;
        flex-grow: 4;
        display: flex;
        align-items: flex-end;
    }
    
    #song-progress-line, #n-song-progress-line {
        height: 7px;
        width: 350px;
        background-color: #ffffff17;
        border-radius: 5px;
    }
    
    #song-progress-pointer, #n-song-progress-pointer {
        height: 7px;
        width: 0%;
        border-radius: 5px;
        background-color: #ffffffa8;
        transition: 0.5s width;
    }
    
    #song-artist, #n-song-artist {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    
    .logo {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    
    .logo img {
        opacity: 0.3;
        height: 35px;
    }
    
    .logo-content {
        flex-direction: column;
        align-items: stretch;
    }
    
    .logo-content > button {
        height: 30px;
        margin-bottom: 10px;
        color: white;
        background: #0076ff;
        border-radius: 5px;
        outline: none;
        border: none;
        font-family: Arial;
    }
    
`;
