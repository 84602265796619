import React, {useEffect, useState} from "react";
import {API} from "../../api";
import {PlayingPage} from "./PlayingPage";
import {Header} from "../../components/Header";
import {useParams} from "react-router";
import {Container} from "../../components/Container";
import {WaitingPage} from "./WaitingPage";
import {useAuth} from "../../hooks/auth";

const api = new API();

export function RoomPage(props) {
    const [room, setRoom] = useState(null);
    const {room_hash} = useParams();
    const {userId} = useAuth();

    useEffect(() => {
        api.getRoom(room_hash).then((resp) => {
            setRoom(resp);
        })
    }, []);

    return <div>
        <Header  />
        <Container paddingTop={room && room.status === "awaiting" ? '50px' : ''}>
            {(room && room.status === "playing") && <PlayingPage room={room} />}
            {(room && room.status === "awaiting") && <WaitingPage room={room} />}
            {!room && <h1>Загрузка...</h1>}
        </Container>
    </div>
}
