import styled from 'styled-components';
import React from "react";

const SBar = styled.div`
  height: 60px;
  position: fixed;
  width: 100%;
  background: #161616;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const SBarButton = styled.div`

    height: 60px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex: 1 1 0px;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
    }
    
    > div img {
        width: 20px;
    }
    
    > div span {
        color: #8E8E8E;
        margin-top: 4px;
        text-align: center;
    }
`;

export function BarButton({text, icon, onClick, isLoading=false}) {
    return <SBarButton onClick={onClick}>
        <div>
            <img src={!isLoading ? icon : "/icons/spinner_gray.gif"} />
            <span>{text}</span>
        </div>
    </SBarButton>
}

export function Bar({children, buttons}) {
    return <SBar>
        {buttons.map(btn => {
            if (!btn) return ;
            return <BarButton key={btn.text} text={btn.text} icon={btn.icon} onClick={btn.onClick} isLoading={btn.isLoading} />
        })}
    </SBar>
}
