import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
   
    
    label {
        
        font-size: 18px;
        font-weight: 600;
    }
`;

const api = new API();

export function InputValueModal({isOpen, setOpen, numRange=[1,5], title, valueDescription, callback, passValue={}, isMobile=isMobileDevice()}) {
    const [options, setOptions] = useState([]);

    const nameInput = useRef(null);

    useEffect(() => {
        const new_opt = [];
        for (let i = numRange[0]; i <= numRange[1]; i++) {
            new_opt.push(<option value={i}>{i}</option>);
        }
        setOptions(new_opt);
    }, [numRange]);


    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>{title}</span></ModalHeader>
        <div>
            <SContent>
                <label>На</label>
                <select ref={nameInput}>
                    {options}
                </select>
                <label>{valueDescription}</label>
            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} onClick={() => {
                setOpen(false);
                callback(nameInput.current.value, passValue);
            }}><span>Применить</span></Button>
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setOpen(false)}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
