import React, {useEffect, useMemo, useRef, useState} from "react";
import {DrawGraphModal} from "../../../../modals/DrawGraphModal";
import {API} from "../../../../api";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js'
import {SGraph, SNoGraph} from "./style";

const api = new API();

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

export function Graph({graphString, startValue, stopValue, length, type, onOpen}) {
    const graphColor = type === "bpm" ? '#0076FF' : '#f45fff';

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Предпочитаемая сортировка треков в очереди по ' + (type === "bpm" ? "BPM" : "Энергичности"),
            },
        },
    };

    const defaultData = {
        labels: [],
        datasets: [
            {
                label: type === "bpm" ? "BPM" : "Энергичность",
                data: [],
                borderColor: graphColor,
                backgroundColor: graphColor,
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 4
            }
        ],
    };

    const [graphData, setGraphData] = useState(defaultData);
    const [dataIsSet, setDataIsSet] = useState(false);



    useEffect(() => {
        if (graphString) {
            let graph_d = JSON.parse(graphString);
            const data = {...defaultData};
            data.labels = [];
            data.datasets[0].data = [];

            for (let i = 1; i <= graph_d.length; i++) {
                data.datasets[0].data.push(graph_d[i-1]);
                data.labels.push(i);
            }
            setGraphData(data);
            setGraphData(prev => {
                setDataIsSet(true);
                return prev;
            })
        }
    }, [graphString]);

    const drawGraph = () => {
        onOpen(startValue, stopValue, length, type);
    };

    return <SGraph>

        {dataIsSet ? <div style={{width: '100%', height: '300px'}} >
                <Chart options={options} data={graphData} type={'line'}  />
            </div> :
            <SNoGraph onClick={drawGraph}>
                <h2>График еще не создан</h2>
                <h3>Нажмите, чтобы создать</h3>
            </SNoGraph>}
    </SGraph>;
}
