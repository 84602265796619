import React, {useEffect, useState} from "react";
import {Header} from "../../../components/Header";
import {Container} from "../../../components/Container";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {BackButton} from "../../../components/BackButton";
import {BlockSide} from "../../../components/Block/BlockSide";
import {RoomStatus} from "../../../styles/HomePage/roomStatus";
import {SPlaylistStats} from "../../PlaylistPage/PlaylistSettingsPage/PlaylistStats/style";

const api = new API();

export function StatsPage({isMobile=isMobileDevice()}) {
    const navigate = useNavigate();
    const [room, setRoom] = useState({});
    const [stats, setStats] = useState({});

    const {room_hash} = useParams();

    const update = () => {
        api.getRoom(room_hash).then((resp) => {
            setRoom(resp);
        });
        api.getRoomStats(room_hash).then(resp => {
            setStats(resp);
        });
    };

    useEffect(() => {
        update()
    }, []);


    return <div>
        <Header  />
        <Container paddingTop="0px">

            <BackButton backPath={`/room/${room_hash}/`} />
            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Статистика комнаты {room.name || ""}</h1>

            <Block smallPadding={isMobile}>
                <BlockSide col={true}>
                    <h3 className="title">Показатели комнаты в среднем за сессию</h3>
                    <SPlaylistStats isMobile={isMobile}>

                        <p>Количество добавленных треков</p>
                        <div>
                            <div>{stats?.tracks_added}</div>
                        </div>

                        <p>Количество голосов за пропуск</p>
                        <div>
                            <div>{stats?.skips}</div>
                        </div>

                        <p>Количество апвоутов</p>
                        <div>
                            <div>{stats?.upvotes}</div>
                        </div>

                        <p>Количество прослушанных треков</p>
                        <div>
                            <div>{stats?.listened}</div>
                        </div>

                        <p>Всего треков прослушано в комнате</p>
                        <div>
                            <div>{stats.total_listened}</div>
                        </div>

                    </SPlaylistStats>
                </BlockSide>
            </Block>

            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Сессии</h1>
            {(stats?.sessions || []).map(x => {
                return <Block key={x.id} value={x.id} onClick={(value) => {
                    navigate(`/room/${room_hash}/session/${value}`);
                }}>
                    <BlockSide><h3>с {x.opened} по {x.closed}</h3></BlockSide>
                    <BlockSide>-></BlockSide>
                </Block>
            })}

        </Container>
    </div>
}
