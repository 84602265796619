import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 10px;
    }
`;

const api = new API();
let interval = null;

export function AuthYmModal({isOpen, setOpen, isMobile=isMobileDevice()}) {
    const [authData, setAuthData] = useState(null);

    const checkAuth = (auth) => {
        if (!auth) return;
        api.authYm(auth.csrf_token, auth.track_id).then((resp) => {
            if (resp.success) {
                setOpen(false);
                window.location.reload();

            }
        })
    };


    useEffect(() => {
        console.log(isOpen);
        if (isOpen) {
            api.getYmQr().then((resp) => {
                setAuthData(resp);
                interval = setInterval(() => {
                    checkAuth(resp);
                }, 2500);
            })
        } else {
            if (interval) {
                clearInterval(interval);
                interval = null;
                setAuthData(null);
            }
        }
    }, [isOpen]);

    const auth = () => {

    };

    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>Авторизация в Яндекс.Музыке</span></ModalHeader>
        <div>
            <SContent>
                {authData && <img src={authData.qr_code} style={{filter: 'invert(1)'}}/>}
                <span style={{color: 'gray', marginTop:"15px"}}>Отсканируйте Qr код камерой приложения Яндекс.Старт</span>
            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setOpen(false)}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
