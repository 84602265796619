import styled from "styled-components";

export const SPlaylistStats = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    ${(props) => props.isMobile ? "font-size: 14px;" : ""}
    
    > p {
        flex: 40%;
        font-weight: 600;
    }
    
    > div {
        flex: 60%;
        display: flex;
        justify-content: flex-start;;
    }
    
    > div > div {
        display: flex;
        width: ${(props) => props.isMobile ? "150px" : "250px"};
        justify-content: center;
        text-align: center;
    }
`;
