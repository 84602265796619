import {useCallback, useEffect, useRef, useState} from "react";
import { useInterval } from 'react-interval-hook';
import Hls from "hls.js";
import {API} from "../../../../api";

const api = new API();

export function Player({trackInfo, volume, setTime, setNode, setPaused, currentIndex, playerIndex, playerTimeSourceIndex, newTrackInfo, crossfading, doCrossfade, preloading}) {
    const [ctid, setCurrentTrackId] = useState(null);
    const [audioLink, setAudioLink] = useState(null);
    const [htmlPlayer, setHtmlPlayer] = useState(null);
    const [playerNode, setPlayerNode] = useState(null);
    const [hlsContext, setHlsContext] = useState(null);

    useInterval(
        () => {
            if (playerNode && playerTimeSourceIndex === playerIndex) {
                setTime(playerNode.currentTime*1000);
            }
        },
        200,
    );

    useEffect(() => {
        if (playerNode) {
            playerNode.volume = volume;
        }
    }, [volume]);

    const getTrackInfo = () => {
        return currentIndex === playerIndex ? trackInfo || {} : newTrackInfo || {};
    };

    const onRefChange = useCallback(node => {
        setPlayerNode(node);
        setNode(node);
        if (node === null) {
        } else {}
    }, []);

    const detectPlatform = (link=audioLink) => {
        return link.includes("/index.m3u8") ? "vk" : "ym";
    };

    const requestNextTrack = (force=false) => {
        if (!preloading) api.nextTrack(trackInfo.room_hash, force);
    };

    const getAudioLink = () => {
        const ti = getTrackInfo();
        if (ti.identifier) {
            api.getAudioLink(ti.platform, ti.identifier).then(resp => {
                if (!resp.audio_link) {
                    setTimeout(() => {
                        requestNextTrack(true);
                    }, 500);
                } else  {
                    setAudioLink(resp.audio_link);
                }
            });
        }

    };

    const doCrossPlay = () => {
        if (preloading) return;

        if (currentIndex === playerIndex) {
            playerNode.play().then(() => {
                emitPlayerReady();
            });
        } else {
            playerNode.play();
            doCrossfade();
            //console.log(playerNode.volume, otherNode.volume);
            //toggleState("init");
            //crossfade.start();
            //setTimeout(() => {
            //    console.log("toggle track", getTrackInfo().title, playerIndex, currentIndex);
            //    toggleState("track");
            //}, 1500);
            //setTimeout(() => {
            //    console.log("toggle indexes", getTrackInfo().title, playerIndex, currentIndex);
            //    toggleState("indexes");
            //}, 3100);
        }
    };

    const destroyPlayer = () => {
        const event = new CustomEvent("destroy", {playerIndex: playerIndex});
        playerNode.dispatchEvent(event);
    };

    const emitPlayerReady = () => {
        const event = new CustomEvent("playerReady", {playerIndex: playerIndex});
        window.dispatchEvent(event);
    }

    const setupPlayer = (node=playerNode) => {
        if (node && audioLink) {
            node.volume = volume;
            if (detectPlatform() === "ym") {
                doCrossPlay();
                setPaused(false);
                node.addEventListener('ended',function() {
                    requestNextTrack();
                },false);
                node.addEventListener("destroy", (e) => {

                    playerNode.pause();
                    setHtmlPlayer(null);
                    setPlayerNode(null);
                    setNode(null);

                });
            } else {
                const video = node;
                if(Hls.isSupported()) {
                    var hls = new Hls({autoStartLoad: true});
                    setHlsContext(hls);
                    hls.loadSource(audioLink);
                    hls.attachMedia(video);
                    hls.on(Hls.Events.MANIFEST_PARSED,function() {
                        doCrossPlay();
                        setPaused(false);
                    });

                }
                else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = audioLink;

                    video.addEventListener('canplay',function() {
                        doCrossPlay();
                        setPaused(false);
                    });
                }
                video.addEventListener('ended',function() {
                    requestNextTrack();
                },false);
                video.addEventListener("destroy", (e) => {
                    console.log("destroy", e);
                    try {
                        hlsContext.destroy();
                        setHlsContext(null);
                    } catch (e) {}
                    setHtmlPlayer(null);
                    setPlayerNode(null);
                    setNode(null);

                })

            }
        }
    };

    useEffect(() => {
        if (getTrackInfo().id !== ctid && !crossfading) {
            console.log("preparing", getTrackInfo().title, playerIndex, currentIndex);
            getAudioLink();
            setCurrentTrackId(getTrackInfo().id);
        }
    }, [trackInfo, newTrackInfo]);

    useEffect(() => {
        if (audioLink) {
            console.log("setup", getTrackInfo().title, playerIndex, currentIndex);
            if (detectPlatform(audioLink) === "ym") {
                setHtmlPlayer(<audio preload="auto" ref={onRefChange}>
                    <source src={audioLink} />
                </audio>);
            } else {
                setHtmlPlayer(<video ref={onRefChange} />);
            }
        }
    }, [audioLink]);

    useEffect(() => {
        if (playerNode && audioLink) setupPlayer();
    }, [playerNode, audioLink]);

    return <div>
        {htmlPlayer}
    </div>;

}
