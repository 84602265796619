import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../Button";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContainer = styled.div`
    display: flex;
    
    > div:not(:last-child) {
        margin-right: 20px;
    }
`;


export function PlatformToggle({setPlatform, hasVk, hasYm, isMobile=isMobileDevice()}) {
    const [platform, setCurrentPlatform] = useState(hasVk ? 'vk' : hasYm ? 'ym' : 'no_platform');

    useEffect(() => {
        setPlatform(platform)
    }, [platform]);

    return <SContainer>
        <Button size={isMobile ? "s" : "xs"} responsive={isMobile} isPrimary={false} active={platform === "vk"} disabled={!hasVk} onClick={() => {
            setCurrentPlatform("vk");
            }
        }>Вк</Button>
        <Button size={isMobile ? "s" : "xs"} responsive={isMobile} isPrimary={false} active={platform === "ym"} disabled={!hasYm} onClick={() => {
            setCurrentPlatform("ym");
        }
        }>Я.Музыка</Button>
    </SContainer>
}
