import {Header} from "../../components/Header";
import React, {useCallback, useEffect, useState} from "react";
import {Container} from "../../components/Container";
import {BackButton} from "../../components/BackButton";
import {useParams} from "react-router";
import {Block} from "../../components/Block";
import {Button} from "../../components/Button";
import {autoCorrelate, process, valuesAtFrequency} from "./utils/audio";
import {getSocket} from "../PlayerPage/utils/ws";
import styled from "styled-components";
import Cookies from "js-cookie"
import {API} from "../../api";
import {NumberField} from "../PlayerPage/components/numberField";

const SAudio = styled.div`
    .circle-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 35px;
        flex-direction: column;
        align-items: center;
    }

    .circle {
        width: 150px;
        height: 150px;
        background: #0076ff;
        border-radius: 100%;
    }
`;

const api = new API();

export function DelaysPage({}) {
    const [audioDelay, setAudioDelay] = useState(0);
    const [websocket, setWebsocket] = useState(null);
    const [is_ws_host, setIsWsHost] = useState(false);
    const [showAudioCircle, setShowAudioCircle] = useState(false);
    const [audioHelpingText, setAudioHelpingText] = useState("");
    const [serverDelta, setServerDelta] = useState(0);
    const [audioTestPlayer, setAudioTestPlayer] = useState(null);

    const {room_hash} = useParams();

    const getDelta = (ts, ct, sourceCt) => {
        let now = (new Date()).getTime() + serverDelta;
        let ts_delta = now - ts;
        let delta = ct - (sourceCt + ts_delta);
        return {ts_delta, delta};
    };

    useEffect(() => {
        api.getTimestampDelta().then(delta => {
            setServerDelta(delta);
            console.log('server delta', delta);
        });
        //const player = new Tone.Player("/sounds/audio_test.mp3").toDestination();
        //setAudioTestPlayer(player);
    }, []);

    const wsCallback = useCallback((event) => {
        const d = JSON.parse(event.data);
        if (d.type === "hello") {
            setIsWsHost(!!d.data.is_host)
        }
        if (d.type === "current_time" && !is_ws_host) {
            const delay = parseInt(document.getElementById("audio_delay_input").value);
            let a = document.getElementById("audio_test");
            if (a.paused) a.play();
            let {ts_delta, delta} = getDelta(d.ts, a.currentTime*1000, d.ct);
            let fin_time = (ts_delta + d.ct + (delay - d.delay)) / 1000;
            console.log("delay", delay, ts_delta, d.ct, d.delay, delta, fin_time, a.currentTime);
            if (Math.abs(a.currentTime - fin_time) > 0.05) {
                console.log("correcting", a.currentTime - fin_time, fin_time);
                a.currentTime = fin_time;
            }
        }
    }, []);

    useEffect(() => {
        if (room_hash) {
            const ws = getSocket(room_hash);
            setWebsocket(prev => {
                if (prev) {
                    try {
                        prev.close()
                    } catch (e) {}
                }
                return ws;
            });

            ws.onmessage = wsCallback;

            ws.onopen = () => {
                ws.send(JSON.stringify({
                    type: "hello",
                    is_host: true
                }))
            }

        }
        return () => {
            if (websocket) websocket.close();
        }
    }, [room_hash]);

    const determineAudioStart = () => {

        process().then(results => {
            console.log(results);
            let min = 100000000;
            for (const r of results) {
                if (r.delay < min) min = r.delay;
            }
            setAudioDelay(parseInt(min));
        });

    };

    const processAudioDelayCheck = () => {
        setAudioHelpingText("Круг должен менять цвет одновременно с щелчками в аудио");
        setShowAudioCircle(true);
        let sequence = [1023, 2204, 3354, 4604];
        document.getElementById("delay_test").play();
        for (const s of sequence) {
            setTimeout(() => {
                document.getElementById("audio-circle").style.background = "#f45fff";
            }, s+parseInt(audioDelay));
            setTimeout(() => {
                document.getElementById("audio-circle").style.background = "#0076ff";
            }, s+100+parseInt(audioDelay))
        }
        setTimeout(() => {
            setAudioHelpingText("");
            setShowAudioCircle(false);
        }, 5000);

    };

    const processAudioSyncCheck = () => {
        document.getElementById("audio_test").play();

        const int = setInterval(() => {
            console.log('sending');
            websocket.send(JSON.stringify({
                type: "broadcast_time",
                object: {
                    type: "current_time", //
                    ts: (new Date()).getTime() + serverDelta,
                    ct: (document.getElementById("audio_test").currentTime * 1000),// + parseInt(audioDelay)
                    delay: parseInt(document.getElementById("audio_delay_input").value)
                }
            }))
        }, 1000);
        setTimeout(() => {
            clearInterval(int);
        }, 60000)
    };

    return <div>
        <Header />
        <Container paddingTop={"0px"}>
            <BackButton backPath={'/view2/'+room_hash+'/view'} />
            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Контроль задержек плеера</h1>
            <Block>
                <SAudio style={{width: '100%'}}>
                    <h2>Задержка аудио</h2>
                    <NumberField name={"audio_delay"} value={audioDelay} setValue={setAudioDelay} />
                    {showAudioCircle && <div className="circle-container">
                        <p>{audioHelpingText}</p>
                        <div className="circle" id="audio-circle" />
                    </div>}
                    <div style={{width: '100%', marginTop: "35px"}}>
                        <Button responsive={true} onClick={determineAudioStart}>Автоматически вычислить задержку</Button>
                        <div style={{marginTop: '10px', display: 'flex', gap: "10px"}}>
                            <Button responsive={true} isPrimary={false} onClick={processAudioDelayCheck}>Проверить задержку</Button>
                            {is_ws_host && <Button responsive={true} isPrimary={false} onClick={processAudioSyncCheck}>Проверить синхронизацию</Button>}
                        </div>

                    </div>
                </SAudio>


            </Block>

            <div style={{display: 'none'}}>
                <audio src={"/sounds/tones6.mp3"} preload="auto" id="tones" />
                <audio src={"/sounds/audio_test.mp3"} preload="auto" id="audio_test" />
                <audio src={"/sounds/delay_test.mp3"} preload="auto" id="delay_test" />
            </div>
        </Container>
    </div>
}
