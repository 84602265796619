import {BlockSide} from "../../../../../components/Block/BlockSide";
import {Block} from "../../../../../components/Block";
import React from "react";
import styled from 'styled-components';
import {isMobileDevice} from "../../../../../hooks/mobile/isMobile";

const SPlayingContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const SPlayingTrack = styled.div`
    display: flex;
    width: 100%;
`;

const SContent = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 22px;
        font-weight: 600;
        ${props => props.isMobile ? "font-size: 16px;" : ""}
    }
    
    span.title {
        font-weight: 400;
        font-size: 18px;
        margin-top: 8px;
        ${props => props.isMobile ? "font-size: 14px; margin-top: 4px; color: #ffffffb3;" : ""}
    }

    img {
        width: 92px;
        margin-right: 26px;
        border-radius: 12px;
        ${props => props.isMobile ? "width: 48px; border-radius: 6px; margin-right: 16px;" : ""}
    }
`;

const SSkip = styled(SContent)`
    flex-grow: 2;
    align-items: flex-end;
    ${props => props.isMobile ? "justify-content: center; margin-right: 16px;" : ""}
    
    div {
        color: #5f5f5f;
        white-space: nowrap;
        font-weight: 600;
    }
`;

export function NowPlayingBlock({title, artist, cover, skip_target, skip_voted, isMobile=isMobileDevice()}) {
    return (<SPlayingContainer>
        <Block width={500} smallPadding={isMobile}>
            <SPlayingTrack>
                <SContent isMobile={isMobile}>
                    <img src={cover} />
                </SContent>
                <SContent isMobile={isMobile}>
                    <span>{artist}</span>
                    <span className="title">{title}</span>
                </SContent>
                <SSkip isMobile={isMobile}>
                    <div>{skip_voted} / {skip_target}</div>
                </SSkip>
            </SPlayingTrack>

    </Block></SPlayingContainer>)
}
