import React, {useEffect, useRef, useState} from "react";

export function Checkbox({name, children, onChange, defaultChecked = false, style={}, isChecked=null, onToggle=undefined, isPrimary=false, disabled=false}) {
    const [checked, setChecked] =  useState(defaultChecked);
    const checkInput = useRef(null);

    const handleClick = () => {

        let v = !checked;
        setChecked(v);
        if (onChange) onChange(v);
    };

    useEffect(() => {
        setChecked(defaultChecked);
        onChange(defaultChecked)
    }, [defaultChecked]);

    return <label htmlFor={name+"-checkbox"} className={"checkbox-container" + (disabled ? " checkbox-disabled" : "")} style={style}>
        <span style={{marginTop: '-2px'}}>{children}</span>
        <input disabled={disabled} type="checkbox" id={name+"-checkbox"} checked={checked} onClick={handleClick} ref={checkInput} />
        <span className={"checkmark" + (isPrimary ? " checkmark-primary" : "")} />
    </label>
}
