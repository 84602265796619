import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";

const SNotifications = styled.div`
    width: 430px;
    height: calc(100dvh - 30px);
    z-index: 100;
    position: absolute;
    right: 0;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    
    .notification-container {
        width: 370px;
        margin-left: 460px;
        transition: 0.6s margin-left, 0.4s opacity;
        padding: 15px;
        background: #34343463;
        border-radius: 10px;
        margin-top: 20px;
        font-family: Arial;
        font-size: 18px;
    }
    
    .notification-container > p {
        margin: 0;
        margin-left: 10px;
    }
    
    .notification-container > div > p {
        font-weight: 600;
        margin: 0;
        margin-bottom: 12px;
    }
    
    .notification-track img {
        width: 50px;
        border-radius: 5px;
        margin-right: 15px;
    }
    
    .notification-track {
        display: flex;
    }
    
    .notification-track > div {
        display: flex;
        flex-direction: column;
    }
    
    .notification-track > div > div {
        font-size: 16px;
    }
    
    .notification-track .not-track-artist {
        font-weight: 600;
        margin-bottom: 5px;
    }

`;

export function Notifications({lastNotification}) {
    const [last, setLast] = useState(lastNotification);
    const container = useRef(null);

    const push = (htmlContent) => {
        let cnt = document.createElement("div");
        cnt.className = "notification-container";
        cnt.innerHTML = htmlContent;
        container.current.appendChild(cnt);

        setTimeout(() => {
            cnt.style.marginLeft = "0px";
        }, 10);

        setTimeout(() => {
            cnt.style.opacity = "0";
            setTimeout(() => {
                cnt.remove();
            }, 400);
        }, 3000);
    }

    useEffect(() => {
        if (last !== lastNotification) {
            push(get_notification_by_data(lastNotification));
            setLast(lastNotification);
        }
    }, [lastNotification]);

    return <SNotifications ref={container}>

    </SNotifications>
}


function get_notification_by_data(d) {
    if (d.event_type === "skip_vote") {
        return "<p>Добавлен голос за пропуск трека</p>";
    } else if (d.event_type === "track_add") {
        return `
            <div>
            <p>В очередь добавлен трек</p>
            <div class="notification-track">
                <img src="${d.event_data.cover}" />
                <div>
                    <div class="not-track-artist">${d.event_data.artist}</div>
                    <div class="not-track-title">${d.event_data.title}</div>
                </div>
            </div>
            </div>
        `
    } else if (d.event_type === "upvote") {
        return `
            <div>
            <p>Добавлен голос за трек</p>
            <div class="notification-track">
                <img src="${d.event_data.cover}" />
                <div>
                    <div class="not-track-artist">${d.event_data.artist}</div>
                    <div class="not-track-title">${d.event_data.title}</div>
                </div>
            </div>
            </div>
        `;
    } else if (d.event_type === "new_playlist") {
        return `
            <div>
            <p>Сейчас играет плейлист</p>
            <div class="notification-track">
                ${d.event_data.playlist_name}
            </div>
            </div>
        `;
    } else if (d.event_type === "no_cd") {
        let postfix = d.event_data.duration < 5 ? "ы" : "";
        return "<p>Включен режим без кулдауна на " + d.event_data.duration + " минут" + postfix + " </p>";
    }
}
