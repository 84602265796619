import styled from 'styled-components';
import React from "react";


const SBlockList = styled.div`
    display: flex;
    flex-direction: column;
`;

export function BlockList({children}) {
    return (
        <SBlockList>{children}</SBlockList>
    )
}
