import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Bpm} from "../../../../components/Track/bpm";
import {Energy} from "../../../../components/Track/energy";
import {SPlaylistStats} from "./style";
import {isMobileDevice} from "../../../../hooks/mobile/isMobile";

export function PlaylistStats({trackData: td, playlist={}, isMobile=isMobileDevice()}) {
    const [trackData, setTrackData] = useState(td);

    useEffect(() => {
        setTrackData(td);
    }, [td]);

    return <SPlaylistStats isMobile={isMobile}>
        <p>Треков в плейлисте / Средний процент прослушивания</p>
        <div>
            <div>{trackData.count} / {playlist.stats?.mean_listens_percentage}%</div>
        </div>
        <p>Количество пропусков треков</p>
        <div>
            <div>{playlist.stats?.skips_count} ({playlist.stats?.skips_count_percentage}%)</div>
        </div>
        <p>Количество добавленных треков</p>
        <div>
            <div>{playlist.stats?.add_count} ({playlist.stats?.add_count_percentage}%)</div>
        </div>
        <p>Средневзвешенные показатели плейлиста</p>
        <div>
            <div>
                <Bpm bpm={trackData.mean_bpm} />
                <Energy energy={trackData.mean_energy} />
            </div>
        </div>
        <p>Медианные показатели плейлиста</p>
        <div>
            <div>
                <Bpm bpm={trackData.median_bpm} />
                <Energy energy={trackData.median_energy} />
            </div>
        </div>
    </SPlaylistStats>
}
