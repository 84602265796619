import {useState} from "react";
import styled from 'styled-components';
import React from "react";
import {isMobileDevice} from "../../hooks/mobile/isMobile";


const SBaseModal = styled.div`
    min-width: 400px;
    max-width: 50dvw;
    background-color: ${({ theme }) => theme.colors.bg};
    border-radius: ${({ theme }) => theme.blocks.radius};
    border: 1px solid ${({ theme }) => theme.colors.border};
    ${props => props.IsMobile ? "margin: 0 20px; width: calc(100% - 40px); min-width: unset; max-width: unset;" : ""}
    
    > * {
        padding: ${({ theme }) => theme.blocks.padding};
    }
`;

const SBaseModalContainer = styled.div`
    ${(props) => props.is_open ? 'display: flex;' : 'display: none;'}
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ba;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

export const ModalHeader = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    
    * {
        font-weight: 600;
        font-size: 18px;
    }
    ${props => isMobileDevice() ? 'text-align: center;' : ""}
    
`;

export const ModalFooter = styled.div`
    display: flex;
    
    > *:not(:last-child) {
        margin-right: 15px;
    }
`;

export function BaseModal({ children, isOpen, setOpen, isMobile=isMobileDevice(), onMouseUp=null }) {

    return <SBaseModalContainer is_open={isOpen} onMouseUp={onMouseUp} >
        <SBaseModal IsMobile={isMobile}>{children}</SBaseModal>
    </SBaseModalContainer>;
}
