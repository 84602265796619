import styled from "styled-components";
import React from "react";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";

const SBpm = styled.div`
    display: flex;
    align-items: center;
    max-width: 130px;
    width: 100%;
    
    justify-content: center;
    
    ${props => props.isMobile ? "max-width: unset; width: 60px;" : ""}

    span {
        border: 2px solid #ffffff55;
        padding: 3px 20px;
        border-radius: 15px;
        color: #ffffff55;
        cursor: default;
        font-weight: 600;
        width: 25px;
        text-align: center;
        ${props => props.isMobile ? "font-size: 12px; width: 22px; font-weight: 400; border-width: 1px; padding: 1px 10px;" : ""}
    }
   

`;

export function Bpm({bpm, isMobile=isMobileDevice()}) {
    return <SBpm isMobile={isMobile}>
        <span>{bpm || "?"}</span>
    </SBpm>;
}
