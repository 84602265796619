export const VolumeSliderStyle = `
    .volume-track {
        border-radius: 7px;
        background: #ffffff22;
        height: 4px;
    }
    
    .volume-track.volume-track-0 {
        background: white;
    }
    
    .volume-track.volume-track-1 {
        background: #ffffff22;
    }
    
    .volume-thumb {
        background: white;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        top: -3px;
        outline: none;
    }
    
    .rangeslider-horizontal, .rangeslider-vertical {
        height: 4px;
        width: 100%;
        border-radius: 7px;
        background: #ffffff22;
        transform: translateX(5px);
      }
      .rangeslider__fill {
        background-color: #ffffff;
        border-radius: 7px;
        height: 4px;
      }
      .rangeslider__handle {
        width: 10px;
        height: 10px;
        top: -3px;
        position: absolute;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 1px 1px #333;
        margin-left: -5px;
        outline: none;
      }
      
      .rangeslider-vertical {
        width: 4px;
        transform: translateX(0px) rotate(180deg);
        height: 150px;
      }
      
      .rangeslider-vertical .rangeslider__fill {
        width: 4px;
      }
      
      .rangeslider-vertical .rangeslider__handle {
        margin-left: -3px;
        position: unset;
        top: 0px;
      }
`;
