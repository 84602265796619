import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    background-color: black;
  }
  
  * {
    color: ${({ theme }) => theme.colors.font};
  }
  
  h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
  }
  
  iframe{
        display: none;
  }
  
  input {
    outline: none;
    border-radius: ${({ theme }) => theme.buttons.radius};
    background: none;
    border: 1px solid ${({ theme }) => theme.colors.input_color};
    padding: 10px 18px;
    font-size: 16px;
  }
  
  div::-webkit-scrollbar-track {
    background-color: #00000000;    
  }
    
  div::-webkit-scrollbar {
    width: 10px;
  }
    
  div::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: 18px;   
   }
   
   .flexVertCenter {
        display: flex;
        align-items: center;
   }
   
   .inlineRoomName {
    display: flex;
    align-items: center;
    justify-content: space-between;
   }
   
   
   
   
   .checkbox-container {
     display: flex;
     margin-top: 10px;
     position: relative;
     padding-left: 29px;
     margin-bottom: 12px;
     cursor: pointer;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     align-items: center;
     align-content: center;

   }
   
   /* Hide the browser's default checkbox */
   .checkbox-container input {
     position: absolute;
     opacity: 0;
     cursor: pointer;
     height: 0;
     width: 0;
   }
   
   /* Create a custom checkbox */
   .checkmark {
     position: absolute;
     top: 0;
     left: 0;
     height: 18px;
     width: 18px;
     background-color: #161616;
     border-radius: 5px;
     border: 1px solid #9f9f9f;
   }
   
   
   
   /* On mouse-over, add a background color */
   .checkbox-container:hover input ~ .checkmark {
     
   }
   
   /* When the checkbox is checked, add a blue background */
   .checkbox-container input:checked ~ .checkmark {
     background-color: #0076ff;
     border-color: #0076ff;
   }
   
   .checkbox-container input:checked ~ .checkmark-primary {
     background-color: #f45fff;
     border-color: #f45fff;
   }
   
   /* Create the checkmark/indicator (hidden when not checked) */
   .checkmark:after {
     content: "";
     position: absolute;
     display: none;
   }
   
   /* Show the checkmark when checked */
   .checkbox-container input:checked ~ .checkmark:after {
     display: block;
   }
   
   /* Style the checkmark/indicator */
   .checkbox-container .checkmark:after {
     left: 5px;
     top: 1px;
     width: 6px;
     height: 11px;
     border: solid white;
     border-width: 0 2px 2px 0;
     border-radius: 1px;
     -webkit-transform: rotate(45deg);
     -ms-transform: rotate(45deg);
     transform: rotate(45deg);
   }
   
   .checkbox-disabled {
    opacity: 0.3;
    cursor: not-allowed;
   }
   
   
   .banner {
        position: absolute;
        top: -10px;
        background: #161616b8;
        left: 0;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        float: left;
        z-index: 5;
        opacity: 1;
        transition: 0.3s opacity;
   }
   
   
   select {
    outline: none;
    background: #161616;;
    padding: 3px 5px;
    border: 1px solid #323232;
    border-radius: 4px;
   }
   
   .button-spinner {
    width: 24px;
   }
`
