export function apply_lyrics_scroll() {
    const cr = document.getElementsByClassName("line-current")[0].getBoundingClientRect();
    const br = document.getElementsByClassName("background")[0].getBoundingClientRect();
    const target_top = ((br.height / 100) * 24)+48;

    const ccr = document.getElementById("lyrics-content").getBoundingClientRect();
    if (Math.abs(Math.abs(ccr.top-cr.top+target_top) - Math.abs(ccr.top)) < 5) return;
    document.getElementById("lyrics-content").style.top = (ccr.top-cr.top+target_top)+"px";
}

export function applyLyrics(lyrics, currentTime) {
    let flag = false;
    if (!lyrics) return;
    for (let i = 0; i < lyrics.length; i++) {
        const line = lyrics[i];

        if (currentTime >= line.startTimeMs) {
            const l = document.getElementById("line-"+i);
            if (!l) continue;
            if (lyrics[i+1] && currentTime > lyrics[i+1].startTimeMs) {
                l.className = "lyrics-line line-gone";
                continue;
            } else  if (!l.classList.contains("line-current")) {
                l.className = "lyrics-line line-current";
                if (lyrics[i+1]) {
                    document.getElementById("line-"+(i+1)).className = "lyrics-line line-next";
                }
                apply_lyrics_scroll();
                flag = true;
            }
            continue;
        }
        if (flag) {
            const l = document.getElementById("line-"+i);
            if (l && (l.classList.contains("line-gone") || l.classList.contains("line-current"))) {
                l.className = "lyrics-line";
            }
        }

    }
}


export function cleanup_after_setup() {
    const r = [...document.getElementsByClassName("line-gone")];
    for (const gl of r) {
        gl.classList.remove("line-gone");
    }

    for (const gl of document.getElementsByClassName("line-next")) {
        gl.classList.remove("line-next");
    }

    const l = document.getElementById("line-0");
    if (l) {
        l.className = "lyrics-line line-next";
    }
}
