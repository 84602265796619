import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {isMobileDevice} from "../../hooks/mobile/isMobile";
import CanvasDraw from "@win11react/react-canvas-draw";
import {GraphMath} from "./graphMath";

const SContent =  styled.div`
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 10px;
    }
`;

const graphMath = GraphMath;

const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
};

const rgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export function DrawGraphModal({isOpen, setOpen, callback, startValue, stopValue, length, isMobile = isMobileDevice(), type="bpm", label="BPM"}) {
    const [isDrawing, setIsDrawing] = useState(false);
    const [allowApply, setAllowApply] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    let saveableCanvas;

    const height = 400;
    const width = 600;

    const low_value = startValue;
    const high_value = stopValue;
    const tracks_count = length;
    const padding = 30;
    const brushColor = "#0076ff";

    const getCanvas = (ind=0) => document.getElementsByClassName("cnv")[0].getElementsByTagName("canvas")[ind];

    const drawGrid = () => {
        const cnv = getCanvas();
        const context = cnv.getContext("2d");

        const w = 0;
        const line_width = 1;
        let y_cnt = 15;
        let x_cnt = 15;
        const x_gap = graphMath.get_grid_gap(width-padding*2, x_cnt);
        const y_gap = graphMath.get_grid_gap(height-padding*2, y_cnt);

        context.font = "14px 'Segoe UI'";
        context.textAlign = 'center';
        const x_labels = graphMath.gen_number_labels(1, tracks_count, x_cnt);
        const y_labels = graphMath.gen_number_labels(low_value, high_value, y_cnt);
        let x_label = 0;
        let y_label = 0;

        for (var x = padding; x <= width-padding; x += x_gap) {
            context.moveTo(w + x, padding);
            context.lineTo(w + x, padding+(y_gap*(y_cnt-1)));
            let l = x_labels[x_label];
            if (l != null) {
                context.fillStyle = '#797979';
                context.fillText(l,x,padding+(y_gap*(y_cnt-1))+14);
            }
            x_label += 1;
        }
        context.textAlign = 'right';
        for (var x = padding; x <= height-padding; x += y_gap) {
            context.moveTo(padding, w + x);
            context.lineTo(padding+(x_gap*(x_cnt-1)), w + x);
            let l = y_labels[y_labels.length-y_label-1];
            if (l !== null) {
                context.fillStyle = '#797979';
                context.fillText(l,padding-5,x+4);
            }
            y_label += 1;
        }

        context.lineWidth = line_width;
        context.strokeStyle = "#444444";

        context.stroke();
    };

    const applyGraph = () => {
        setShowMessage(false);
        callback(saveableCanvas.getDataURL("image/png", false), type);
    };

    const handleDown = () => {
        setIsDrawing(true);
        saveableCanvas.undo();
        setAllowApply(false);
    };

    const analyzeLine = () => {
        const cnv = getCanvas(1);
        const context = cnv.getContext("2d");
        let has_in = false;
        let has_out = false;
        for (let y = padding; y < height-padding; y++) {
            let p = context.getImageData(padding, y, 1, 1).data;
            let hex = rgbToHex(p[0], p[1], p[2]);

            if (hex === brushColor) {
                has_in = true;
                break;
            }
        }

        for (let y = padding; y < height-padding; y++) {
            let p = context.getImageData(width-padding, y, 1, 1).data;
            let hex = rgbToHex(p[0], p[1], p[2]);
            if (hex === brushColor) {
                has_out = true;
                break;
            }
        }

        if (!has_in || !has_out) {
            saveableCanvas.undo();
            setShowMessage(true);
        } else {
            setAllowApply(true);
        }
    };

    const handleUp = () => {
        if (isDrawing) {
            setIsDrawing(false);
            analyzeLine();

        }
    };

    useEffect(() => {
        if (isOpen) {
            setTimeout(drawGrid, 100);
        }
    }, [isOpen]);

    return <BaseModal isOpen={isOpen} setOpen={setOpen} onMouseUp={handleUp}>
        <ModalHeader><span>Нарисуйте график изменения {label}</span></ModalHeader>
        <div>
            <SContent>
                <div onMouseDown={handleDown}>
                    {isOpen && <CanvasDraw
                                           ref={canvasDraw => (saveableCanvas = canvasDraw)}
                                           className={"cnv"}
                                           hideInterface={true}
                                           brushColor={brushColor}
                                           style={{background: "#161616", border: '1px solid #3b3b3b'}}
                                           hideGrid={true}
                                           canvasWidth={width+"px"}
                                           canvasHeight={height+"px"}
                                           brushRadius={2}
                    />}
                </div>
                <div style={{textAlign: 'left'}}>
                    {showMessage && <p>График должен пересекать левую и правую крайние вертикальные оси</p>}
                </div>

            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} onClick={applyGraph} disabled={!allowApply}><span>Применить</span></Button>
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => {
                setOpen(false);
                setShowMessage(false);
            }}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
