import {useCallback, useEffect, useState} from "react";

function ntp(t0, t1, t2, t3) {
    return {
        roundtripdelay: (t3 - t0) - (t2 - t1),
        offset: ((t1 - t0) + (t2 - t3)) / 2
    };
}

function getTime() {
    return (new Date()).getTime();
}

const storage = {
    t0: null,
    s: {

    }
};

export function useNtpAlgorithm(ws) {
    const [setup, setSetup] = useState(false);
    const [timeOffset, setTimeOffset] = useState(null);

    useEffect(() => {
        if (ws) {
            ws.addEventListener("message", (event) => {
                const data = JSON.parse(event.data);
                if (data.type === "pong") {
                    const t1 = data.ts,
                        t2 = data.ts,
                        t3 = getTime();
                    const c = ntp(storage.t0, t1, t2, t3);
                    setTimeOffset(c.offset);

                    //console.log("NTP delay:", c.roundtripdelay, "NTP offset:", c.offset, "corrected: ", (new Date(t3 + c.offset)));
                }
            });

        }

    }, [ws]);

    useEffect(() => {
        update();

    }, [setup]);

    const update = useCallback(() => {
        storage.t0 = getTime();
        if (ws) ws.send(JSON.stringify({type: "ping"}));
    }, [ws, timeOffset]);

    return {update, timeOffset};



}
