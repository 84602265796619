import {NeedAuthModal} from "../modals/NeedAuthModal";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";

export function AuthMiddleware({children}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [hasAuth, setHasAuth] = useState(null);

    useEffect(() => {
        const cookie = Cookies.get("user_id");
        const after_auth = Cookies.get("after_auth");
        if (cookie) {
            setHasAuth(true);
            if (after_auth && after_auth.length > 1) {
                Cookies.set("after_auth", "");
                window.location.href = after_auth;
            }
        } else {
            Cookies.set("after_auth", window.location.pathname);
            setModalOpen(true);
        }
    }, []);

    return <div>
        {children}
        <NeedAuthModal isOpen={modalOpen} setOpen={setModalOpen} />
    </div>
}
