import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {isTouchDevice} from "../../playerUtils";

const SnoMobile = `
    #n-song-image {
        max-width: 70%;
        margin: 70px 0;
        
    }
    
    #n-song-artist, #n-song-title {
        display: none;
    }
`;


const SnoMedia = styled.div`
    height: 100dvh;
    position: absolute;
    width: 100dvw;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: ${props => props.show ? "flex" : "none"};
    opacity: ${props => props.fade};
    transition: 0.5s opacity;
    
    
    .n-center {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: white;
        height: 100dvh;
    }
    
    #n-song-artist, .n-song-progress-container {
        margin-top: 10px;
    }
    
    .n-song-progress-container {
        margin-top: 13px;
        width: 400px;
        opacity: 0;
    }
    
    #n-song-progress-line {
        width: 400px;
        
    }
    
    #n-song-image {
        width: 400px;
        border-radius: 15px;
    }
    
    #n-song-progress-pointer {
         width: 0%;
    }
    
    #n-song-artist {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 4px;
        margin-top: 25px;
    }
    
    #n-song-title {
        font-size: 20px;
        opacity: 0.5;
        font-weight: 300;
    }
    
    ${props => props.isMobile ? SnoMobile : ""}
`;

export function NoMediaScreen({trackInfo, hasRich, isMobile=isTouchDevice()}) {
    const [fade, setFade] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (hasRich) {
            setFade(0);
            setTimeout(() => {
                setShow(false);
            }, 500);
        } else {
            setFade(1);
            setTimeout(() => {
                setShow(true);
            }, 500);
        }
    }, [hasRich]);

    return <SnoMedia show={show} fade={fade} isMobile={isMobile}>
        <div className="n-center">
            <img id="n-song-image" src={trackInfo.cover || ""} />
            <span id="n-song-artist">{trackInfo.title || "Загрузка..."}</span>
            <span id="n-song-title">{trackInfo.artist}</span>
            <div className="n-song-progress-container">
                <div id="n-song-progress-line">
                    <div id="n-song-progress-pointer"></div>
                </div>
            </div>
        </div>
    </SnoMedia>
}
