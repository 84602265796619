import React, {useEffect, useRef, useState} from "react";
import {Header} from "../../components/Header";
import {Container} from "../../components/Container";
import {useAuth} from "../../hooks/auth";
import {API} from "../../api";
import {BlockList} from "../../components/Block/BlockList";
import {Block} from "../../components/Block";
import {useNavigate} from "react-router";
import {RoomStatus} from "../../styles/HomePage/roomStatus";
import {BlockSide} from "../../components/Block/BlockSide";
import {Button} from "../../components/Button";
import {CreateRoomModal} from "../../modals/CreateRoomModal";
import {FloatingContainer} from "../../components/FloatingContainer";
import {AuthVkModal} from "../../modals/AuthVkModal";
import {AuthYmModal} from "../../modals/AuthYmModal";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const api = new API();

export function HomePage({isMobile = isMobileDevice()}) {
    const [isCreateRoomOpen, setCreateRoomOpen] = useState(false);
    const [isVkOpen, setVkOpen] = useState(false);
    const [isYmOpen, setYmOpen] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [heightOffset, setHeightOffset] = useState('0px');
    const {userId, user} = useAuth();
    const navigate = useNavigate();
    const floatingRef = useRef(null);

    useEffect(() => {
        if (!floatingRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setHeightOffset(floatingRef.current.clientHeight+'px');
        });
        resizeObserver.observe(floatingRef.current);
        return () => resizeObserver.disconnect();
    }, [floatingRef]);

    useEffect(() => {
        if (isCreateRoomOpen) return;

        api.getRooms().then((rooms) => {
            setRooms(rooms);
        })
    }, [isCreateRoomOpen]);

    return (<div>
        <Header />
        <Container heightOffset={heightOffset}>
            <div className="inlineRoomName">
                <h1>Список комнат</h1>
                {isMobile &&
                    <Button size="icon" onClick={() => {setCreateRoomOpen(true)}}>
                        <img src="/icons/plus.png" style={{width:  "16px"}} />
                    </Button>
                }
            </div>
            <BlockList>
                {rooms && rooms.map((room) =>
                    <Block key={room.id} value={room.hash} onClick={(value) => {
                        navigate(`/room/${value}/`);
                    }}>
                        <BlockSide><h3>{room.name}</h3></BlockSide>
                        <BlockSide><RoomStatus status={room.status} /></BlockSide>
                    </Block>
                )}
            </BlockList>
            <div>
                {!isMobile && <Button onClick={() => {setCreateRoomOpen(true)}}>Создать комнату</Button>}
            </div>
            <FloatingContainer innerRef={floatingRef}>
                {(user && !user.has_vk_music) && <Button isPrimary={false} size={isMobile ? "l" : "m"} responsive={isMobile} onClick={() => {setVkOpen(true)}}>Подключить Вк Музыку</Button>}
                {(user && !user.has_ym_music) && <Button isPrimary={false} size={isMobile ? "l" : "m"} responsive={isMobile} onClick={() => {setYmOpen(true)}}>Подключить  Я.Музыку</Button>}
            </FloatingContainer>
            <CreateRoomModal isOpen={isCreateRoomOpen} setOpen={setCreateRoomOpen} />
            <AuthVkModal isOpen={isVkOpen} setOpen={setVkOpen} />
            <AuthYmModal isOpen={isYmOpen} setOpen={setYmOpen} />
        </Container>
        </div>
    )
}
