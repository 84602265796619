import {SPlayerPage} from "./style";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {API} from "../../api";
import {Background} from "./components/background";
import {Lyrics} from "./components/lyrics";
import {Screen} from "./components/screen";
import {NoMediaScreen} from "./components/noMediaScreen";
import {Player} from "./components/player";
import {useInterval} from "react-interval-hook";
import {applyLyrics} from "./components/lyrics/utils";
import useTimeout from "../../utils/utils";
import {getSocket} from "./utils/ws";
import {Notifications} from "./components/notifications";
import {Button} from "../../components/Button";
import Cookies from "js-cookie";
import {Checkbox} from "../../components/Checkbox";
import {NumberField} from "./components/numberField";
import {CookieParameter} from "./components/cookieParameter";

const api = new API();

export function PlayerPage({}) {
    const [currentTrackInfo, setCurrentTrackInfo] = useState({});
    const [newTrackInfo, setNewTrackInfo] = useState({});
    const [hasRich, setHasRich] = useState(false);
    const [hasLyrics, setHasLyrics] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(1);
    const [showControls, setShowControls] = useState(false);

    const [volume, setVolume] = useState(0.1);
    const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
    const [playerNode1, setPlayerNode1] = useState(null);
    const [playerNode2, setPlayerNode2] = useState(null);
    const [crossfading, setCrossfading] = useState(false);
    const [playerTimeSourceIndex, setPlayerTimeSourceIndex] = useState(1);
    const [playerPaused, setPlayerPaused] = useState(true);

    const [preloading, setPreloading] = useState(false);
    const [cancelPreloading, setCancelPreloading] = useState(false);
    const [preloadingTimeoutDelay, setPreloadingTimeoutDelay] =  useState(0);

    const [websocket, setWebsocket] = useState(null);
    const [lastNotification, setLastNotification] = useState({});

    const [audioDelay, setAudioDelay] = useState(0);
    const [audioDelayCompensation] = useState(0);
    const [syncClients, setSyncClients] = useState(false);
    const [is_ws_host, setIsWsHost] = useState(false);
    const [lastBroadcastMessage, setLastBroadcastMessage] = useState(null);
    const [serverDelta, setServerDelta] = useState(0);

    const [weakConnection, setWeakConnection] = useState(false);
    const [updateTimeInterval, setUpdateTimeInterval] = useState(1000);
    const [hqVideo, setHqVideo] = useState(null);

    const {room_hash} = useParams();
    const navigate = useNavigate();

    const getDelta = (ts, ct, sourceCt) => {
        let now = (new Date()).getTime() + serverDelta;
        let ts_delta = now - ts;
        let delta = ct - (sourceCt + ts_delta);
        return {ts_delta, delta};
    };

    const toggleControls = () => {
        setShowControls(prev => !prev);
    };

    const setPlayerTime = (t) => {
        setCurrentPlayerTime(t + audioDelay);
    };


    useEffect(() => {
        if (room_hash) {
            const ws = getSocket(room_hash);
            setWebsocket(prev => {
                if (prev) {
                    try {
                        prev.close()
                    } catch (e) {}
                }
                return ws;
            });
            ws.onmessage = (event) => {
                console.log(event.data);
                const d = JSON.parse(event.data);
                if (d.type === "hello") {
                    setIsWsHost(!!d.data.is_host)
                } else if (d.type === "current_time" && !is_ws_host) {
                    setLastBroadcastMessage(d);
                } else if (d.type === "event") {
                    setLastNotification(d);
                }
            };

            ws.onopen = () => {
                ws.send(JSON.stringify({
                    type: "hello",
                    is_host: true
                }))
            }
        }

        api.getRoom(room_hash).then(res => {
            setHqVideo(res.prefer_hq);
        });

        return () => {
            if (websocket) websocket.close();
        }
    }, [room_hash]);

    useEffect(() => {
        if (lastBroadcastMessage) {
            let d = lastBroadcastMessage;
            const delay = parseInt(document.getElementById("audio-delay-input").value);
            let a = getPlayerNode();
            if (a.paused) return;
            let {ts_delta, delta} = getDelta(d.ts, a.currentTime*1000, d.ct);
            let fin_time = (ts_delta + d.ct + (delay - d.delay)) / 1000;
            if (Math.abs(a.currentTime - fin_time) > 0.05) a.currentTime = fin_time;
        }
    }, [lastBroadcastMessage]);

    const sync = () => {
       if (!syncClients) return;
        websocket.send(JSON.stringify({
            type: "broadcast_time",
            object: {
                type: "current_time",
                ts: (new Date()).getTime() + serverDelta,
                ct: currentPlayerTime,
                delay: parseInt(document.getElementById("audio-delay-input").value)
            }
        }))
    };

    useEffect(() => {
        api.getTimestampDelta().then(delta => {
            setServerDelta(delta);
            console.log('server delta', delta);
        });
    }, []);

    const getPlayerNode = () => {
        return currentPlayerIndex === 1 ? playerNode1 : playerNode2;
    };

    const playAudio = () => {
        if (getPlayerNode()) {
            playerNode1.play();
        }
    };

    const checkAndDoNext = () => {
        api.getRoomStatus(room_hash).then(resp => {
            if (resp.current_track?.id !== newTrackInfo.id) {
                api.nextTrack(room_hash);
            }
        })
    };

    const update = () => {
        api.getRoomStatus(room_hash).then((resp) => {
            if (resp.current_track) {
                const info = {...resp.current_track, ...resp.current_track_rich, skip_target: resp.skip_target, skip_voted: resp.skip_voted, room_hash: room_hash};
                if (!crossfading && !preloading) {
                    if (!info.cover) info.cover = "/icons/default_cover_large.png";
                    if (!currentTrackInfo.id || currentTrackInfo.id === info.id) {
                        setCurrentTrackInfo(info);
                        setHasLyrics(!!resp.current_track_rich?.lyrics);
                    } else {
                        setNewTrackInfo(info);
                    }
                }


            }
            //else window.location.reload();
        });
    };

    const {reset: resetPreloadingTimeout} = useTimeout(() => {
        (currentPlayerIndex === 1 ? playerNode2 : playerNode1).play();
        checkAndDoNext();
        doCrossfade();
    }, preloadingTimeoutDelay);

    const updateUI = (ct=currentPlayerTime) => {
        let percentage = Math.min((currentPlayerTime/1000) / (currentTrackInfo.duration/100), 100);
        document.getElementById("song-progress-pointer").style.width = percentage+"%";
        document.getElementById("n-song-progress-pointer").style.width = percentage+"%";
        applyLyrics(currentTrackInfo.lyrics, ct);
    };

    const preloadTrack = () => {
        setPreloading(true);
        api.preloadTrack(room_hash).then(res => {
            console.log("preload", res);
            if (res.success && res.track.id) {
                setNewTrackInfo(res.track);
                const delay = Math.max(currentTrackInfo.duration*1000 - currentPlayerTime - 3000, 0);
                setPreloadingTimeoutDelay(delay);
                setPreloadingTimeoutDelay(prev => {
                    resetPreloadingTimeout();
                    return prev;
                });
            } else {
                setPreloading(false);
                setCancelPreloading(true);
            }

        })
    };

    useEffect(() => {
        updateUI(currentPlayerTime);
        const delta = weakConnection ? 12000 : 8000;
        if ((currentTrackInfo.duration*1000) - currentPlayerTime <= delta && !preloading && !cancelPreloading) {
            preloadTrack();
        }
    }, [currentPlayerTime]);

    useInterval(() => {
        update();
        sync();
    }, updateTimeInterval);

    useEffect(() => {
        update();

    }, []);

    useEffect(() => {
        setHasRich(hasVideo || hasLyrics);
    }, [hasVideo, hasLyrics]);

    useEffect(() => {
        console.log(weakConnection, "wq");
        if (weakConnection) setUpdateTimeInterval(3000);
        else setUpdateTimeInterval(1000);
    }, [weakConnection]);

    const doCrossfade = () => {
        (currentPlayerIndex === 1 ? playerNode2 : playerNode1).volume = 0;
        setCrossfading(true);
        crossfade.start();
        setTimeout(() => {
            setCurrentTrackInfo(newTrackInfo);
            setNewTrackInfo(prev => {});
            setPlayerTimeSourceIndex(currentPlayerIndex === 1 ? 2 : 1);
        }, 1500);
        setTimeout(() => {
            console.log("toggle cpi", currentPlayerIndex);
            setCurrentPlayerIndex(currentPlayerIndex === 1 ? 2 : 1);
            setCurrentPlayerIndex(prev => {
                setCrossfading(false);
                console.log("stopped cf");
                try {
                    const event = new CustomEvent("destroy", {playerIndex: prev === 1 ? 2 : 1});
                    (prev === 1 ? playerNode2 : playerNode1).dispatchEvent(event);
                } catch (e) {
                }
                setPreloading(false);
                setCancelPreloading(false);
                return prev;
            })

        }, 3100);
    };

    const crossfade = useInterval(() => {
        const next_node = currentPlayerIndex === 1 ? playerNode2 : playerNode1;
        const current_node = currentPlayerIndex === 1 ? playerNode1 : playerNode2;
        const speed = volume / (3000 / 50);
        let new_inc_vol = next_node.volume + speed;
        let new_dec_vol = current_node.volume - speed;
        if (new_inc_vol >= volume || new_dec_vol <= 0) {
            next_node.volume = volume;
            current_node.volume = 0;
            console.log("stop");
            crossfade.stop();
        } else {
            next_node.volume = new_inc_vol;
            current_node.volume = new_dec_vol;
        }
    }, 50, {autoStart: false});

    const updateHqVideo = (v) => {
        setHqVideo(v);
        api.saveRoomSettings(room_hash, {prefer_hq: v});
    };


    return <SPlayerPage>
        <div className="main">
            <Background trackInfo={currentTrackInfo} hasVideo={hasVideo} setHasVideo={setHasVideo} currentTime={currentPlayerTime} weakConnection={weakConnection} hasLyrics={hasLyrics} />
            <Lyrics trackInfo={currentTrackInfo} setHasLyrics={setHasLyrics} />
            <Screen trackInfo={currentTrackInfo}  hasRich={hasRich} />
            <NoMediaScreen trackInfo={currentTrackInfo} hasRich={hasRich} />


            <div className="dots">
                <div id="dot-1"></div>
                <div id="dot-2"></div>
            </div>
            <div className="logo" style={{position: "absolute", top: "5px", left: "5px", zIndex: "100"}}>
                <img src="/klzm.png" onClick={toggleControls}/>
                <div className="logo-content" style={{display: showControls ? "flex" : "none"}}>
                    <input style={{padding: 0, margin: "10px 0px"}} type="range" id="volume-slider" min="0" max="100" value={volume * 100} onChange={(v) => {
                        setVolume(v.target.value / 100)
                    }} />
                        <button onClick={playAudio}>Play</button>
                    <button onClick={() => {
                        navigate(`/view2/${room_hash}/delays`);
                    }}>Контроль задержек</button>
                    <NumberField style={{marginTop: '5px'}} name={"audio_delay"} value={audioDelay} setValue={setAudioDelay} />
                    <Checkbox name={"sync-clients"} onChange={(v) => setSyncClients(v)}>Синхронизация клиентов</Checkbox>
                    <Checkbox name={"weak-connection"} onChange={setWeakConnection} defaultChecked={weakConnection}>Слабое соединение</Checkbox>
                    {hqVideo !== null && <Checkbox name={"hq-video"} onChange={updateHqVideo} defaultChecked={hqVideo}>FullHD видео</Checkbox>}
                </div>

            </div>
        </div>

        <div id="players_container" style={{display: "none"}}>
            <Player trackInfo={currentTrackInfo} volume={volume} setTime={setPlayerTime} setNode={setPlayerNode1} setPaused={setPlayerPaused} playerIndex={1} currentIndex={currentPlayerIndex} newTrackInfo={newTrackInfo} crossfading={crossfading} doCrossfade={doCrossfade} preloading={preloading} playerTimeSourceIndex={playerTimeSourceIndex} />
            <Player trackInfo={currentTrackInfo} volume={volume} setTime={setPlayerTime} setNode={setPlayerNode2} setPaused={setPlayerPaused} playerIndex={2} currentIndex={currentPlayerIndex} newTrackInfo={newTrackInfo} crossfading={crossfading} doCrossfade={doCrossfade} preloading={preloading} playerTimeSourceIndex={playerTimeSourceIndex} />
        </div>
        <Notifications lastNotification={lastNotification} />

        <CookieParameter name={"weak_connection"} value={weakConnection} setValue={setWeakConnection} mutateFn={v => v === "true"} />
    </SPlayerPage>
}
